import styled from "styled-components";

export const Home = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--ghostwhite);

  width: 100%;
  height: 100%;
`;

export const Navbar = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(267.07deg, #0C3167 0%, #3867AB 100%);
  border-top:1px solid #391761;

  width: 100%;
  height: 80px;
`;

export const StepContainer = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(265.99deg, #54368C 8.86%, #7C52B0 83.78%);
  border-top:1px solid #391761;

  width: 100%;
  height: 250px;
`;

