import styled from "styled-components";

export const HomeCardsContainer = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color:  var(--very-light-blue);

  width: 100%;
  height: 100%;

  padding-top: 100px;
  padding-bottom: 50px;

  font-family: 'Montserrat', sans-serif;


  @media(max-width: 2560px) {
    padding-left: var(--laptop-4k-side-margin);
    padding-right: var(--laptop-4k-side-margin);
  }

  @media(max-width: 1950px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media(max-width: 1400px) {
    padding-left: var(--laptop-g-side-margin);
    padding-right: var(--laptop-g-side-margin);
  }

  @media(max-width: 1300px) {
    padding-left: var(--laptop-p-side-margin);
    padding-right: var(--laptop-p-side-margin);

    padding-top: 50px;
  }

  @media(max-width: 1024px) {
    padding-left: var(--tablet-side-margin);
    padding-right: var(--tablet-side-margin);

    padding-top: 50px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color:  var(--very-light-blue);
  border-top:1px solid #391761;

  font-family: 'Montserrat', sans-serif;


  font-size: 24px;
  font-weight: 600;
  color: black;

  padding-top: 50px;
  padding-left: 22%;

  width: 100%;
  height: 100%;

  @media(max-width: 2560px) {
    padding-left: var(--laptop-4k-side-margin);
    padding-right: var(--laptop-4k-side-margin);
  }

  @media(max-width: 1440px) {
    padding-left: var(--laptop-g-side-margin);
    padding-right: var(--laptop-g-side-margin);
  }

  @media(max-width: 1024px) {
    padding-left: var(--laptop-p-side-margin);
    padding-right: var(--laptop-p-side-margin);
  }

  @media(max-width: 768px) {
    padding-left: var(--tablet-side-margin);
    padding-right: var(--tablet-side-margin);
  }

  @media(max-width: 425px) {
    padding-left: var(--movel-g-side-margin);
    padding-right: var(--movel-g-side-margin);
    padding-bottom: 20px;
  }
`;

export const TitleLine = styled.div`
  border-bottom: 5px solid var(--hydrangea-blue);
  height: 10px;
  width:50px ;
  font-family: 'Montserrat', sans-serif;

`;
