import React from "react";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

import Banner2560_01 from "../../../../Assets/Home/Carousel/2560/Banner_2560x500_4k.png";
import Banner1440_01 from "../../../../Assets/Home/Carousel/1440/Banner_1440x500_Laptop_Grande.png";
import Banner1024_01 from "../../../../Assets/Home/Carousel/1000/Banner_1024x500_Laptop.png";
import Banner768_01 from "../../../../Assets/Home/Carousel/768/banner_ 768x500_Tablet.png";
import Banner425_01 from "../../../../Assets/Home/Carousel/425/banner_425x500_DM_grande.png";
import Banner375_01 from "../../../../Assets/Home/Carousel/375/banner_375x500_DM_medio.png";

// TODO imagem para celulares menores
// import Banner320_01 from "../../../../../Assets/Home/Carousel/320/banner_320x500_DM_pequeno.png";

import { useContext } from "react";
import { HomeContext } from "../../../../Contexts/HomeContext";

function Home() {
  const { banners } = useContext(HomeContext);

  return (
    <Carousel
      style={styles}
      showThumbs={false}
      swipeable={true}
      emulateTouch={true}
      showStatus={false}
    >
      {/* // ! Array de imagens deve ser inserido na ordem decrescente no back, mudar o formato da classe ou a lógica no front */}
      {banners ? (
        banners.map((banner) => {
          return (
            <div>
              <picture>
                <source
                  srcSet={banner.images[1].image}
                  media="(min-width: 1440px)"
                />
                <source
                  srcSet={banner.images[2].image}
                  media="(min-width: 1024px)"
                />
                <source
                  srcSet={banner.images[3].image}
                  media="(min-width: 768px)"
                />
                <source
                  srcSet={banner.images[4].image}
                  media="(min-width: 425px)"
                />
                <source
                  srcSet={banner.images[5].image}
                  media="(min-width: 375px)"
                />
                <source
                  srcSet={banner.images[6].image}
                  media="(min-width: 320px)"
                />
                <img src={banner.images[0].image} alt="" />
              </picture>
            </div>
          );
        })
      ) : (
        <div>
          <picture>
            <source srcSet={Banner1440_01} media="(min-width: 1440px)" />
            <source srcSet={Banner1440_01} media="(min-width: 1024px)" />
            <source srcSet={Banner1024_01} media="(min-width: 768px)" />
            <source srcSet={Banner768_01} media="(min-width: 425px)" />
            <source srcSet={Banner425_01} media="(min-width: 375px)" />
            <source srcSet={Banner375_01} media="(min-width: 320px)" />
            <img src={Banner2560_01} alt="" />
          </picture>
        </div>
      )}
    </Carousel>
  );
}

export default Home;

