export function makePayloadCartaoAdyen(
  invoiceId,
  contractId,
  paymentAdyenData,
  totalPrice,
  name = ""
) {
  const { paymentMethod, installments } = paymentAdyenData.cardData;
  delete paymentMethod.checkoutAttemptId;
  const [firstName, lastName] = extractNames(name);

  return {
    reference: invoiceId,
    contractId,
    paymentData: {
      paymentMethod,
      installments,
      shopperName: {
        firstName,
        lastName,
      },
    },
    value: totalPrice * 100,
    deliveryDate: new Date(new Date().setMinutes(new Date().getMinutes() + 30)),
  };
}
export function makePayloadBankSlipAdyen(
  invoiceId,
  contractId,
  requisitionData,
  totalPrice,
  name = ""
) {
  const jsonRequisitionData = JSON.parse(requisitionData);
  const { customer } = jsonRequisitionData.purchase;
  const { address } = customer;
  const [firstName, lastName] = extractNames(name);

  return {
    reference: invoiceId,
    contractId,
    paymentData: {
      billingAddress: {
        postalCode: address.zipcode,
        country: "BR",
        stateOrProvince: estadosBrasileiros.find(
          ({ label }) => address.state.toUpperCase() === label
        ).value,
        city: address.city,
        street: address.street,
        houseNumberOrName: address.number,
      },
      paymentMethod: { type: "boletobancario" },
      installments: { value: 1 },
      socialSecurityNumber: customer.cpf,
      shopperName: {
        firstName,
        lastName,
      },
    },
    value: totalPrice * 100,
    deliveryDate: getFutureDate(new Date()),
  };
}

function extractNames(fullName) {
  const nameParts = fullName.split(" ");

  const firstName = nameParts[0];

  const lastName = nameParts.slice(1).join(" ");

  return [firstName, lastName];
}

const getFutureDate = (inputDate) =>
  new Date(new Date(inputDate).setDate(new Date(inputDate).getDate() + 3));
const estadosBrasileiros = [
  {
    label: "ACRE",
    value: "AC",
  },
  {
    label: "ALAGOAS",
    value: "AL",
  },
  {
    label: "AMAPÁ",
    value: "AP",
  },
  {
    label: "AMAZONAS",
    value: "AM",
  },
  {
    label: "BAHIA",
    value: "BA",
  },
  {
    label: "CEARÁ",
    value: "CE",
  },
  {
    label: "DISTRITO FEDERAL",
    value: "DF",
  },
  {
    label: "ESPIRITO SANTO",
    value: "ES",
  },
  {
    label: "GOIAS",
    value: "GO",
  },
  {
    label: "MARANHÃO",
    value: "MA",
  },
  {
    label: "MATO GROSSO",
    value: "MT",
  },
  {
    label: "MATO GROSSO DO SUL",
    value: "MS",
  },
  {
    label: "MINAS GERAIS",
    value: "MG",
  },
  {
    label: "PARÁ",
    value: "PA",
  },
  {
    label: "PARAÍBA",
    value: "PB",
  },
  {
    label: "PARANÁ",
    value: "PR",
  },
  {
    label: "PERNAMBUCO",
    value: "PE",
  },
  {
    label: "PIAUÍ",
    value: "PI",
  },
  {
    label: "RIO DE JANEIRO",
    value: "RJ",
  },
  {
    label: "RIO GRANDE DO NORTE",
    value: "RN",
  },
  {
    label: "RIO GRANDE DO SUL",
    value: "RS",
  },
  {
    label: "RONDONIA",
    value: "RO",
  },
  {
    label: "RORAIMA",
    value: "RR",
  },
  {
    label: "SANTA CATARINA",
    value: "SC",
  },
  {
    label: "SÃO PAULO",
    value: "SP",
  },
  {
    label: "SERGIPE",
    value: "SE",
  },
  {
    label: "TOCATINS",
    value: "TO",
  },
];

