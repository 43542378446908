import styled, { css } from 'styled-components';
import { MdOutlineCancel } from "react-icons/md";

export const Container = styled.div`
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d5dbeb;
  font-family: 'Montserrat', sans-serif;

  img {
    width: 100%;
  }

  .description {
    margin-top: 25px;
  }

  p {
    color: var(--hydrangea-blue);
    font-size: 14px;
    text-align: justify;
  }

  @media (max-width: 1024px) {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 425px) {
    p {
      font-size: 12px;
    }
  }
`;

export const Title = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
  color: var(--hydrangea-blue);
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;

`;

export const BlueBoxesContainer = styled.div`
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;

`;


export const BlueBox = styled.div`
  background-color: var(--very-light-blue);
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;

  p {
    color: var(--black);
    font-size: 1rem;
    text-align: center;
  }

  &:not(:first-child) {
    margin-top: 15px;
  }

  @media (max-width: 1024px) {
    p {
      font-size: 14px;
    }
  }
`;


export const RadioInputs = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: space-between;
  margin-top: 15px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;

  .inputGroup {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: ${(props) => (props.direction === "row" ? "0px" : "10px")};
    }

    label {
      color: var(--hydrangea-blue);
      margin-left: 5px;
    }
  }

  @media (max-width: 768px) {
    .inputGroup {
      label {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 425px) {
    .inputGroup {
      label {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 425px) {
    flex-direction: column;

    .inputGroup:not(:first-child) {
      margin-top: 10px;
    }
  }
`;


export const RadioButton = styled.input`
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;

`;


export const CheckboxInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  justify-items: center;
  font-family: 'Montserrat', sans-serif;

  @media(max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media(max-width: 900px) {
    grid-template-columns: 1fr;
    justify-items: start;
  }
`;


export const CheckboxButton = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 200px;
  padding: 5px 10px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;

  color: var(--hydrangea-blue);
  background-color: var(--very-light-blue);
  border-radius: 5px;
  border: 1px solid var(--hydrangea-blue);

  ${(props) =>
    props.selected &&
    css`
      color: var(--dark-blue);
      background-color: var(--light-blue);
      border: 1px solid var(--dark-blue);
    `}
`;


export const DatesShow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: 'Montserrat', sans-serif;

  & > div {
    position: relative;
  }
`;


export const DateShow = styled.input`
  padding: 5px 10px;
  margin-top: 20px;
  width: 200px;
  color: var(--hydrangea-blue);
  background-color: var(--very-light-blue);
  border-radius: 5px;
  border: 1px solid var(--hydrangea-blue);
  outline: none;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;

  ${(props) =>
    props.disabled &&
    css`
      color: var(--dark-blue);
      background-color: var(--light-blue);
      border: 1px solid var(--dark-blue);
    `}
`;


export const DeleteIcon = styled(MdOutlineCancel)`
  margin-top: 2px;
  color: rgb(170 55 55);
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;

`;


export const StyledButton = styled.button`
    padding: 5px;
    width: 100px;
    color: #fff;
    background-color: var(--hydrangea-blue);
    border: none;
    border-radius: 3px;
    margin-left: 10px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;

`;


export const DateInput = styled.input`
  padding: 5px 10px;
  margin-top: 20px;
  width: 200px;
  color: var(--hydrangea-blue);
  background-color: var(--very-light-blue);
  border-radius: 5px;
  border: 1px solid var(--hydrangea-blue);
  outline: none;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;

`;
