import React, { createContext, useState } from "react";
import useLocalStorage from "../Hooks/useLocalStorage";

export const PlanContext = createContext();

export function PlanProvider({ children }) {
  // dados da compra
  const [specialDay, setSpecialDay] = useLocalStorage("specialDay", []);
  const [dates, setDates] = useLocalStorage("dates", []);
  const [price, setPrice] = useLocalStorage("price", {
    plan: "",
    arrangementType: "",
    flowerArrangements: "",
  });
  const [basePrice, setBasePrice] = useState();
  const [arrangementTypePrice, setArrangementTypePrice] = useLocalStorage(
    "arrangementTypePrice",
    0
  );
  const [arrangementQuantityPrice, setArrangementQuantityPrice] =
    useLocalStorage("arrangementTypePrice", 0);

  // TODO passar para objeto {plan: sType: flowerArrangements: }
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedArrangementType, setSelectedArrangementType] = useLocalStorage(
    "selectedArrangementType",
    { id: "", name: "" }
  );
  const [flowerArrangements, setFlowerArrangements] = useLocalStorage(
    "flowerArrangements",
    "3"
  );

  // * infos do One
  const [testimonials, setTestimonials] = useState();
  const [useTerms, setUseTerms] = useState();
  const [arrangementsTypes, setArrangementsTypes] = useState("");
  const [holidaysDates, setHolidaysDates] = useState();

  const [planName, setPlanName] = useLocalStorage("planName", "");
  const [planImage, setPlanImage] = useState();
  const [planDescription, setPlanDescription] = useState();
  const [planId, setPlanId] = useState();
  const [plan_id, setPlan_id] = useState("");
  const [blueBoxes, setBlueBoxes] = useState();

  const [plan, setPlan] = useState();

  function getArrangementTypePrice(arrangementName) {
    const arrangementTypePricesArray = plan.priceConfiguration.filter((hit) => {
      return hit.arrangement === arrangementName;
    });
    const arrangementPrice = arrangementTypePricesArray.filter((hit) => {
      return hit.quantity === 3;
    });

    // mudar o valor do adicional da quantidade quando muda o tipo de arranjo
    getArrangementQuantityPrice(flowerArrangements, arrangementName);

    //? valoresArranjo[3 arranjos] - basePrice
    setArrangementTypePrice(arrangementPrice[0].price[0].value - basePrice);
  }

  function getArrangementQuantityPrice(numberOfArrangements, arrangementName) {
    // tratamento para identificar quando a chamada veio da mudança de quantidade ou
    // da mudança do tipo de arranjo
    if (arrangementName === undefined) {
      if (selectedArrangementType.name !== "") {
        arrangementName = selectedArrangementType.name;
      } else {
        arrangementName = plan.priceConfiguration[0].arrangement;
      }
    }

    const arrangementTypePricesArray = plan.priceConfiguration.filter((hit) => {
      return hit.arrangement === arrangementName;
    });
    let arrangementBasePrice = arrangementTypePricesArray.filter((hit) => {
      return hit.quantity === 3;
    });
    let arrangementTotalPrice = arrangementTypePricesArray.filter((hit) => {
      return hit.quantity === Number(numberOfArrangements);
    });

    // calcular quanto é o adicional pela quantidade de arranjos

    //? valoresArranjo[x arranjos] - valoresArranjo[3 arranjos]
    // const quantityPriceAdditional

    setArrangementQuantityPrice(
      arrangementTotalPrice[0].price[0].value -
        arrangementBasePrice[0].price[0].value
    );
  }

  return (
    <PlanContext.Provider
      value={{
        dates,
        setDates,
        specialDay,
        setSpecialDay,
        selectedPlan,
        setSelectedPlan,
        arrangementsTypes,
        setArrangementsTypes,
        selectedArrangementType,
        setSelectedArrangementType,
        flowerArrangements,
        setFlowerArrangements,
        holidaysDates,
        setHolidaysDates,
        testimonials,
        setTestimonials,
        useTerms,
        setUseTerms,
        planId,
        setPlanId,
        plan_id,
        setPlan_id,
        planName,
        setPlanName,
        planImage,
        setPlanImage,
        planDescription,
        setPlanDescription,
        blueBoxes,
        setBlueBoxes,
        price,
        setPrice,
        plan,
        setPlan,

        // prices
        basePrice,
        setBasePrice,
        arrangementTypePrice,
        setArrangementTypePrice,
        arrangementQuantityPrice,
        setArrangementQuantityPrice,

        getArrangementTypePrice,
        getArrangementQuantityPrice,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}

// planOptions -> arrangementTypes
// selectedPlanOption -> selectedPlanarrangementType

