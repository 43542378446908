import sendRequest from "../../../../../Services/sendRequest";
import sendRequestGatewayPayment from "../../../../../Services/sendRequestGatewayPayment";
import {
  makePayloadBankSlipAdyen,
  makePayloadCartaoAdyen,
} from "./factoryPayload";

export class BankSlipPurchase {
  contractId = "";
  invoiceId = "";
  constructor(requisitionData, filial, totalPrice, name) {
    this.requisitionData = requisitionData;
    this.filial = filial;
    this.totalPrice = totalPrice;
    this.possuiErroSydle = false;
    this.possuiErroAdyen = false;
    this.name = name;
    this.renderedObject = "";
  }
  async execute() {
    await this.createSydleContract();
    if (this.possuiErroSydle) {
      return;
    }
    await this.createAdyenBankSlip();
    if (this.possuiErroAdyen) {
      return;
    }
  }
  async createSydleContract() {
    try {
      const { data: responseCreateSydleContract } = await sendRequest.purchase(
        this.requisitionData
      );

      this.contractId = responseCreateSydleContract.contractId;
      this.invoiceId = responseCreateSydleContract.invoiceId;
    } catch (error) {
      this.possuiErroSydle = error;
      return;
    }
  }
  async createAdyenBankSlip() {
    try {
      const payloadAdyenBankSlip = makePayloadBankSlipAdyen(
        this.invoiceId,
        this.contractId,
        this.requisitionData,
        this.totalPrice,
        this.name
      );

      const response = await sendRequestGatewayPayment.postCheckoutBankSlip(
        payloadAdyenBankSlip,
        this.filial
      );
      if (response.status === 200) {
        if (
          response.data.size === 0 ||
          response.data.type !== "application/pdf"
        ) {
          throw new Error("Não foi possível renderizar o pdf.");
        }

        this.renderedObject = URL.createObjectURL(response.data);
      }
    } catch (error) {
      this.possuiErroAdyen = error;
      return;
    }
  }
}

