const adyenStyles = {
  base: {
    color: "#001b2b",
    fontSize: "16px",
    fontWeight: "400",
    fontSmoothing: "antialiased",
    fontFamily: "Montserrat",
  },
  error: {
    color: "red",
  },
  placeholder: {
    color: "#d8d8d8",
  },
  validated: {
    color: "green",
  },
};
export { adyenStyles };

