//React ======================================================================
import React, { useContext } from "react";
//Style Components ===========================================================
import {
  StepByStepContainer,
  Step,
  EllipseWithLine,
  Ellipse,
  Line,
  StepText,
  Title,
  TitleLine,
  StepDescription,
} from "./style";
//Contexts ===================================================================
import { HomeContext } from "../../../../Contexts/HomeContext";
//============================================================================
import { IsDesktopOrLaptop } from "../../../../Hooks/Responsive";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function StepByStep() {
  const { howItWorksSection } = useContext(HomeContext);

  return IsDesktopOrLaptop() ? (
    <>
      <Title>
        {howItWorksSection ? howItWorksSection.title : "Como Funciona"}
        <TitleLine />
      </Title>

      <StepByStepContainer>
        <Step>
          <EllipseWithLine>
            <Ellipse borderColor="#f4c27a">1</Ellipse>
            <Line color="#f4c27a" />
          </EllipseWithLine>
          <StepText>
            {howItWorksSection ? (
              howItWorksSection.steps[0].title
            ) : (
              <Skeleton width="70%" baseColor="#3867AB" />
            )}
          </StepText>
          <StepDescription>
            {howItWorksSection ? (
              howItWorksSection.steps[0].description
            ) : (
              <Skeleton width="90%" count={3} baseColor="#3867AB" />
            )}
          </StepDescription>
        </Step>
        <Step>
          <EllipseWithLine>
            <Line color="#f4c27a" />
            <Ellipse>2</Ellipse>
            <Line />
          </EllipseWithLine>
          <StepText>
            {howItWorksSection ? (
              howItWorksSection.steps[1].title
            ) : (
              <Skeleton width="70%" baseColor="#3867AB" />
            )}
          </StepText>
          <StepDescription>
            {howItWorksSection ? (
              howItWorksSection.steps[1].description
            ) : (
              <Skeleton width="90%" count={3} baseColor="#3867AB" />
            )}
          </StepDescription>
        </Step>
        <Step>
          <EllipseWithLine>
            <Line />
            <Ellipse>3</Ellipse>
            <Line />
          </EllipseWithLine>
          <StepText>
            {howItWorksSection ? (
              howItWorksSection.steps[2].title
            ) : (
              <Skeleton width="70%" baseColor="#3867AB" />
            )}
          </StepText>
          <StepDescription>
            {howItWorksSection ? (
              howItWorksSection.steps[2].description
            ) : (
              <Skeleton width="90%" count={3} baseColor="#3867AB" />
            )}
          </StepDescription>
        </Step>
        <Step>
          <EllipseWithLine style={{ marginRight: "45%" }}>
            <Line />
            <Ellipse>4</Ellipse>
          </EllipseWithLine>
          <StepText>
            {howItWorksSection ? (
              howItWorksSection.steps[3].title
            ) : (
              <Skeleton width="70%" baseColor="#3867AB" />
            )}
          </StepText>
          <StepDescription>
            {howItWorksSection ? (
              howItWorksSection.steps[3].description
            ) : (
              <Skeleton width="90%" count={3} baseColor="#3867AB" />
            )}
          </StepDescription>
        </Step>
      </StepByStepContainer>
    </>
  ) : null;
}

export default StepByStep;

