import React, { useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import sendRequest from "../../Services/sendRequest";

// Global Components
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";

// Sections
import LeftSection from "./Sections/LeftSection";
import RightSection from "./Sections/RightSection";

// Styles
import { Content } from "./style";

import { ChakraProvider } from "@chakra-ui/react";

// Contexts
import { PlanContext } from "../../Contexts/PlanContext";

function Plano() {
  const { planId } = useParams();

  const {
    setPlanId,
    setPlan_id,
    setPlanName,
    setPlanImage,
    setPlanDescription,
    setBlueBoxes,
    setArrangementsTypes,
    setSelectedArrangementType,
    setHolidaysDates,
    setTestimonials,
    setUseTerms,
    setPlan,
    setBasePrice,
  } = useContext(PlanContext);

  const history = useHistory();

  useEffect(() => {
    async function getPlan() {
      const result = await sendRequest.getPlan({ planIdentifier: planId });

      if (
        result &&
        result.testimonials &&
        // result.useTerms &&
        result.plan &&
        result.plan.id &&
        result.plan._id &&
        result.plan.name &&
        result.plan.image &&
        result.plan.description &&
        result.plan.blueBoxes &&
        result.plan.arrangementsTypes &&
        result.plan.basePrice &&
        result.holidays
      ) {
        setTestimonials(result.testimonials);
        setUseTerms(result.useTerms);

        setPlanId(result.plan.id);
        setPlan_id(result.plan._id);
        setPlanName(result.plan.name);
        setPlanImage(result.plan.image);
        setPlanDescription(result.plan.description);
        setBlueBoxes(result.plan.blueBoxes);

        setArrangementsTypes(result.plan.arrangementsTypes);

        const uniqueArrangements = [
          ...new Map(
            result.plan.priceConfiguration.map((item) => [
              item["arrangement"],
              item,
            ])
          ).values(),
        ];

        setArrangementsTypes(uniqueArrangements);
        setSelectedArrangementType({
          id: uniqueArrangements[0].arrangementId,
          name: uniqueArrangements[0].arrangement,
        });

        setPlan(result.plan);
        setBasePrice(result.plan.basePrice);

        setHolidaysDates(result.holidays);
      } else {
        history.push("/");
      }
    }

    getPlan();
  }, [
    planId,
    setArrangementsTypes,
    setBasePrice,
    setBlueBoxes,
    setHolidaysDates,
    setPlan,
    setPlanDescription,
    setPlanId,
    setPlanImage,
    setPlanName,
    setPlan_id,
    setTestimonials,
    setUseTerms,
  ]);

  return (
    <ChakraProvider>
      <Navbar />

      <Content>
        <LeftSection />

        <RightSection />
      </Content>

      <Footer />
    </ChakraProvider>
  );
}

export default Plano;

