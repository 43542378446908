//Style Components ===========================================================
import React from "react";
import {
  Accordions,
  Container,
  Fotter,
  Header,
  Column,
  Image,
  List,
  From,
  From2,
  FromLine,
  Price,
  SubTitle,
  Title,
  Button,
  Background,
  Crown,
  FreshFlowerLabel,
  StyledAccordionButton,
  StyledPanel,
  StyledAccordionItem,
} from "./style";
import Lottie from "react-lottie";
//============================================================================
import { Link } from "react-router-dom";
import { IsDesktopOrLaptopG } from "../../../../Hooks/Responsive";
import CrownIcon from "../../../../Assets/Home/crown.json";
import { BASE_URL_FLOWERS_CLUB } from "../../../../App";
//============================================================================
import priceFormat from "../../../../Utils/priceFormat";
import { Accordion, AccordionIcon, AccordionItem, Box } from "@chakra-ui/react";
//============================================================================

//Configuração do Ícone Animado
const defaultCrownIcon = {
  loop: true,
  autoplay: true,
  animationData: CrownIcon,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function DarkCard({ plan }) {
  return IsDesktopOrLaptopG() ? (
    <Background>
      <Container>
        <Header>
          <Column>
            <SubTitle>PLANO ANUAL</SubTitle>
            <Title>{plan && plan.name}</Title>
            <FreshFlowerLabel>by Fresh Flower</FreshFlowerLabel>
          </Column>
          <Crown>
            <Lottie
              options={defaultCrownIcon}
              height={150}
              width={150}
              isClickToPauseDisabled
            />
          </Crown>
        </Header>
        <Image src={plan && plan.image} />
        <Accordions>
          {/* // ! Erro no card causado pelos 4 itens do plano */}
          {plan &&
            plan.planArrangementsTypes.map((hit, index) => {
              return (
                <>
                  <Accordion
                    defaultIndex={[0]}
                    style={{ width: "100%" }}
                    allowToggle
                    allowMultiple
                    key={index}
                  >
                    <AccordionItem>
                      <StyledAccordionButton>
                        <Box flex="1" textAlign="left">
                          {hit.title}
                        </Box>
                        <AccordionIcon />
                      </StyledAccordionButton>
                      <StyledPanel pb={4}>
                        <List>
                          {hit.items.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </List>
                      </StyledPanel>
                    </AccordionItem>
                  </Accordion>
                </>
              );
            })}
        </Accordions>
        <Fotter>
          <From>A PARTIR DE</From>
          <FromLine>
            <From2 style={{ marginRight: 5 }}>3x de R$ </From2>
            <Price>{plan && priceFormat.homeCardPrice(plan.basePrice)}</Price>
          </FromLine>
          <a
            href={`${BASE_URL_FLOWERS_CLUB}${plan && plan.identifier}/`}
            style={{ textDecoration: "none" }}
          >
            <Button>ASSINE AGORA</Button>
          </a>
        </Fotter>
      </Container>
    </Background>
  ) : (
    <Container>
      <Header>
        <Column>
          <SubTitle>PLANO ANUAL</SubTitle>
          <Title>{plan && plan.name}</Title>
          <FreshFlowerLabel>by Fresh Flower</FreshFlowerLabel>
        </Column>
        <Crown>
          <Lottie
            options={defaultCrownIcon}
            height={100}
            width={100}
            isClickToPauseDisabled
          />
        </Crown>
      </Header>
      <Image src={plan && plan.image} />
      <Accordions>
        {plan &&
          plan.planArrangementsTypes.map((hit, index) => {
            return (
              <>
                <Accordion
                  defaultIndex={[0]}
                  style={{ width: "100%" }}
                  allowToggle
                >
                  <StyledAccordionItem>
                    <StyledAccordionButton>
                      <Box flex="1" textAlign="left">
                        {hit.title}
                      </Box>
                      <AccordionIcon />
                    </StyledAccordionButton>
                    <StyledPanel pb={4}>
                      <List>
                        {hit.items.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </List>
                    </StyledPanel>
                  </StyledAccordionItem>
                </Accordion>
              </>
            );
          })}
      </Accordions>
      <Fotter>
        <From>A PARTIR DE</From>
        <FromLine>
          <From2 style={{ marginRight: 5 }}>3x de R$ </From2>
          <Price>{plan && priceFormat.homeCardPrice(plan.basePrice)}</Price>
        </FromLine>
        <a
          href={`${BASE_URL_FLOWERS_CLUB}${plan && plan.identifier}/`}
          style={{ textDecoration: "none" }}
        >
          <Button>ASSINE AGORA</Button>
        </a>
      </Fotter>
    </Container>
  );
}

export default DarkCard;

