import React, { useState, useEffect } from "react";
import { InputContainer, Input, InputTitle } from "./style";
import validator from "validator";
import { cpf } from "cpf-cnpj-validator";

function InputComponent(props) {
  const [error, setError] = useState(false);
  let { creditCardValidation, emptyValidation, cvcDigitsValidation, lastNameValidation, emailValidation, cpfValidation, phoneValidation, check, value } = props;

  useEffect(() => {
    function validationInside(value) {
      if (check) {
        //Validação de Sobrenome
        if (lastNameValidation) {
          let space = value.indexOf(" ");

          if (space !== -1 && value[space + 1] && value[space + 2]) {
            setError(false);
          } else {
            setError(true);
          }
        }

        //Validação se está vazio
        if (emptyValidation) {
          if (value && value !== "" && value !== " ") {
            setError(false);
            props.setError(false);
          } else {
            setError(true);
            props.setError(true);
          }
        }

        //Verificar se é um cartão de crédito
        if (creditCardValidation) {
          if (validator.isCreditCard(value)) {
            setError(false);
          } else {
            setError(true);
          }
        }

        //Validação de Espaço no Início do Input e se é número inteiro
        if (value[0] !== " " && cvcDigitsValidation) {
          if (validator.isInt(value)) {
            props.onChange(value);
          }
        }

        //Validação se é um e-mail vaĺido
        if (emailValidation && validator.isEmail(value)) {
          setError(false);
        } else if (emailValidation) {
          setError(true);
        }

        //Valida se uma string é igual a outra
        if (props.equalValidation && props.equalValidation === value) {
          setError(false);
        } else if (props.equalValidation) {
          setError(true);
        }

        //Validador de CPF
        if (cpfValidation && cpf.isValid(value)) {
          setError(false);
        } else if (cpfValidation) {
          setError(true);
        }

        //Valida se é um Telefone
        if (phoneValidation && validator.isMobilePhone(value, "pt-BR")) {
          setError(false);
        } else if (phoneValidation) {
          setError(true);
        }

        //Validação de Espaço no Início do Input
        if (value[0] !== " ") {
          //Valida Se é um Número, quando for CVC
          if (
            cvcDigitsValidation &&
            (validator.isInt(value) || value === "")
          ) {
            props.onChange(value);
          }

          if (!cvcDigitsValidation) {
            props.onChange(value);
          }
        }
      }
    }

    if (value) validationInside(value);
  }, [props, check, value, lastNameValidation, emptyValidation, creditCardValidation, cvcDigitsValidation, emailValidation, cpfValidation, phoneValidation]);

  function validation(value) {
    if (check) {
      //Validação de CEP
      if (props.zipcodeValidation) {
        if (value && value.length === 8) {
          setError(false);
          props.setError(false);
        } else {
          setError(true);
          props.setError(true);
        }
      }

      //Validação se está vazio
      if (emptyValidation) {
        if (value && value !== "" && value !== " ") {
          setError(false);
          props.setError(false);
        } else {
          setError(true);
          props.setError(true);
        }
      }

      //Validação de Sobrenome
      if (lastNameValidation) {
        let space = value.indexOf(" ");

        if (space !== -1 && value[space + 1] && value[space + 2]) {
          setError(false);
          props.setError(false);
        } else {
          setError(true);
          props.setError(true);
        }
      }

      //Verificar se é um cartão de crédito
      if (creditCardValidation) {
        if (validator.isCreditCard(value)) {
          setError(false);
          props.setError(false);
        } else {
          setError(true);
          props.setError(true);
        }
      }

      //Validação se é um e-mail vaĺido
      if (emailValidation && validator.isEmail(value)) {
        setError(false);
        props.setError(false);
      } else if (emailValidation) {
        setError(true);
        props.setError(true);
      }

      //Valida se uma string é igual a outra
      if (props.equalValidation && props.equalValidation === value) {
        setError(false);
        props.setError(false);
      } else if (props.equalValidation) {
        setError(true);
        props.setError(true);
      }

      //Validador de CPF
      if (cpfValidation && cpf.isValid(value)) {
        setError(false);
        props.setError(false);
      } else if (cpfValidation) {
        setError(true);
        props.setError(true);
      }

      //Valida se é um Telefone
      if (phoneValidation && validator.isMobilePhone(value, "pt-BR")) {
        setError(false);
        props.setError(false);
      } else if (phoneValidation) {
        setError(true);
        props.setError(true);
      }
    }

    //Validação de Espaço no Início do Input
    if (value[0] !== " " && props.onChange) {
      //Valida Se é um Número, quando for CVC
      if (
        cvcDigitsValidation &&
        (validator.isInt(value) || value === "")
      ) {
        props.onChange(value);
      }

      if (!cvcDigitsValidation) {
        props.onChange(value);
      }
    }
  }

  return (
    <InputContainer half={props.half}>
      <InputTitle error={check && (!value || value === "") ? true : error}>{props.title}</InputTitle>
      <Input
        placeholder={props.placeholder}
        contrast={props.contrast}
        onChange={(e) => validation(e.target.value)}
        value={value}
        maxLength={cvcDigitsValidation ? cvcDigitsValidation : null}
        type={props.number ? "number" : "text"}
        error={
          check && (!value || value === "") ? true : error
        }
        disabled={props.disabled}
      />
    </InputContainer>
  );
}

export default InputComponent;

