import {
  AccordionButton,
  AccordionPanel,
  AccordionItem,
} from "@chakra-ui/react";
import styled from "styled-components";
import Flower from "../../../../Assets/flower_home.png";

export const Background = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Montserrat", sans-serif;

  background-image: url(${Flower});

  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;

  width: 480px;
  height: 60%;

  padding-left: 220px;
  padding-bottom: 120px;
`;

export const Container = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  /* dark-blue */
  font-family: "Montserrat", sans-serif;

  border: 1px solid #0c3167;
  border-radius: 10px;

  /*   margin-right: ${(props) => (props.first ? 200 : 0)}px;
 */
  width: 400px;
  height: 750px;
`;

export const Header = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: "Montserrat", sans-serif;

  background: var(--ghostwhite);

  border-bottom: 1px solid var(--dark-blue);
  box-sizing: border-box;

  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;

  margin-right: ${(props) => (props.first ? 200 : 0)}px;
  padding: 20px;

  width: 100%;
  height: 10%;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--dark-blue);
  font-family: "Montserrat", sans-serif;
`;

export const SubTitle = styled.div`
  font-size: 1.2rem;
  color: var(--dark-blue);
  font-family: "Montserrat", sans-serif;
`;

export const Image = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: "Montserrat", sans-serif;

  background-image: ${(props) => `url(${props.src})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* dark-blue */

  margin-right: ${(props) => (props.first ? 200 : 0)}px;
  padding-left: 20px;

  width: 100%;
  height: 20%;
`;

export const Accordions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Montserrat", sans-serif;

  background: var(--ghostwhite);

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  margin-right: ${(props) => (props.first ? 200 : 0)}px;
  padding: 0 20px;
  width: 100%;
  height: 60%;
`;

export const StyledAccordionButton = styled(AccordionButton)`
  background: #d9dce3;
  color: #323232;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  margin-top: 15px;

  transition: 0.5s;
  cursor: pointer;
  padding: 18px;
  border: none;
  outline: none;

  &:hover {
    background-color: #ecedf8;
  }
`;

export const StyledPanel = styled(AccordionPanel)`
  background: #ecedf8;

  color: #323232;
  font-size: 14px;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: "Montserrat", sans-serif;

  height: 80px;
  overflow: auto;

  // !important para sobrescrever os styles da biblioteca
  padding: 0 10px 0 !important;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 10px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a2a2a2;
  }
`;

export const StyledAccordionItem = styled(AccordionItem)`
  text-align: left;
  font-family: "Montserrat", sans-serif;
`;

export const List = styled.ul`
  margin: 10px 10px;
  list-style: none;
  font-family: "Montserrat", sans-serif;

  li {
    position: relative;
    &:not(:first-child) {
      margin-top: 3px;
    }

    &::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 1px;
      background-color: #323232;
      left: -10px;
      top: 50%;
    }
  }
`;

export const Fotter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;

  background: var(--ghostwhite);

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  margin-right: ${(props) => (props.first ? 200 : 0)}px;
  width: 100%;
  height: 15%;

  padding-bottom: 40px;
`;

export const FromLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
  font-family: "Montserrat", sans-serif;
`;

export const From = styled.div`
  margin-left: -50px;
  margin-bottom: 10px;
  margin-top: 10px;

  font-size: 1.5rem;
  color: var(--dark-blue);
  font-family: "Montserrat", sans-serif;
`;

export const From2 = styled.div`
  margin-left: 0px;

  color: var(--dark-blue);

  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
`;

export const Price = styled.div`
  font-size: 2rem;
  font-weight: 800;
  color: var(--dark-blue);
  font-family: "Montserrat", sans-serif;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 300px;

  padding: 10px;

  margin-top: 20px;

  background: #3867ab;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 700px) {
    width: 200px;
  }
`;

export const LinkA = styled.a`
  font-family: "Montserrat", sans-serif;
`;

