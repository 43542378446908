import React, { useEffect, useState } from "react";

//Style Components ===========================================================
import {
  Container,
  Logo,
  Row,
  Column,
  RedirectTo,
  Redirect,
  Hambuguer,
  LinkC,
} from "./style";

import { IsTabletOrMobile } from "../../Hooks/Responsive";
import { AiOutlineMenu } from "react-icons/ai";
import sendRequest from "../../Services/sendRequest";

import previewLogo from "../../Assets/logo-clube-das-flores.png";
import { BASE_URL_FLOWERS_CLUB } from "../../App";
//============================================================================

function Navbar() {
  const [isLoading, setIsLoading] = useState();
  const [heightMenu, setHeightMenu] = useState(80);
  const [logo, setLogo] = useState();

  useEffect(() => {
    async function getNavbar() {
      const result = await sendRequest.getNavbar({});

      if (result && result.link) {
        setLogo(result.link);
      }
    }

    getNavbar();
  }, [setLogo]);

  return (
    <Container height={heightMenu} id="inicio">
      {!IsTabletOrMobile() ? (
        <Column back="red" logo={true}>
          {isLoading ? (
            <LinkC href={BASE_URL_FLOWERS_CLUB}>
              <Logo src={previewLogo} />
            </LinkC>
          ) : (
            <LinkC href={BASE_URL_FLOWERS_CLUB}>
              <Logo src={logo} />
            </LinkC>
          )}
        </Column>
      ) : null}

      {!IsTabletOrMobile() ? (
        <Column links={true}>
          <RedirectTo href={BASE_URL_FLOWERS_CLUB}>Início</RedirectTo>
          <RedirectTo href={BASE_URL_FLOWERS_CLUB + "#planos"}>
            Planos
          </RedirectTo>
          <RedirectTo href={BASE_URL_FLOWERS_CLUB + "#duvidas"}>
            Dúvidas
          </RedirectTo>
          <RedirectTo href={BASE_URL_FLOWERS_CLUB + "#contato"}>
            Contato
          </RedirectTo>
          <Redirect
            background="white"
            color="var(--dark-blue)"
            href="https://grupozelo.com/"
          >
            Grupo Zelo
          </Redirect>
        </Column>
      ) : null}

      {IsTabletOrMobile() ? (
        <Row>
          {isLoading ? (
            <LinkC href={BASE_URL_FLOWERS_CLUB}>
              <Logo src={previewLogo} />
            </LinkC>
          ) : (
            <LinkC href={BASE_URL_FLOWERS_CLUB}>
              <Logo src={logo} />
            </LinkC>
          )}

          <Hambuguer
            onClick={() =>
              heightMenu === 80 ? setHeightMenu(400) : setHeightMenu(80)
            }
          >
            <AiOutlineMenu size={50} color="white" />
          </Hambuguer>
        </Row>
      ) : null}

      {IsTabletOrMobile() && heightMenu !== 80 ? (
        <>
          <Row>
            <RedirectTo
              href={BASE_URL_FLOWERS_CLUB}
              onClick={() => setHeightMenu(80)}
            >
              Início
            </RedirectTo>
          </Row>
          <Row>
            <RedirectTo
              href={BASE_URL_FLOWERS_CLUB + "#planos"}
              onClick={() => setHeightMenu(80)}
            >
              Planos
            </RedirectTo>
          </Row>
          <Row>
            <RedirectTo
              href={BASE_URL_FLOWERS_CLUB + "#duvidas"}
              onClick={() => setHeightMenu(80)}
            >
              Dúvidas
            </RedirectTo>
          </Row>
          <Row>
            <RedirectTo
              href={BASE_URL_FLOWERS_CLUB + "#contato"}
              onClick={() => setHeightMenu(80)}
            >
              Contato
            </RedirectTo>
          </Row>
          <Row>
            <Redirect
              background="white"
              color="var(--dark-blue)"
              href="https://grupozelo.com/"
            >
              Grupo Zelo
            </Redirect>
          </Row>
        </>
      ) : null}
    </Container>
  );
}

export default Navbar;

