import React, { useEffect, useState, useContext } from "react";
import { useToast } from "@chakra-ui/react";

import { Container, Paper, InputsContainer } from "./style";

import Input from "../../Components/Inputbox";
import Combobox from "../../Components/Combobox";
import Button from "../../Components/Button";
import Card from "../../Components/Card";

import sendRequest from "../../../../Services/sendRequest";
//Context ===========================================================================
import { CheckoutContext } from "../../../../Contexts/CheckoutContext";
//===================================================================================

function Address(props) {
  const toast = useToast();

  const {
    zipcode,
    setZipcode,
    street,
    setStreet,
    city,
    setCity,
    state,
    setState,
    number,
    setNumber,
    neighborhood,
    setNeighborhood,
    complement,
    setComplement,
  } = useContext(CheckoutContext);

  const [streetDisabled, setStreetDisabled] = useState(false);
  const [cityDisabled, setCityDisabled] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateDisabled, setStateDisabled] = useState(false);
  const [neighborhoodDisabled, setNeighborhoodDisabled] = useState(false);

  //Liberar Verificação de Erros ====================================================
  const [check, setCheck] = useState(false);
  //=================================================================================

  const [zipcodeError, setZipcodeError] = useState(false);
  const [streetError, setStreetError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [neighborhoodError, setNeighborhoodError] = useState(false);
  const [complementError, setComplementError] = useState(false);

  function next() {
    if (
      zipcode &&
      !zipcodeError &&
      street &&
      !streetError &&
      city &&
      !cityError &&
      state &&
      !stateError &&
      number &&
      !numberError &&
      neighborhood &&
      !neighborhoodError &&
      ((complement && !complementError) || !complement)
    ) {
      props.setKeepGoingE(true);
      props.setTabIndex(2);

      toast({
        title: "Dados de Endereço Preenchidos",
        description: "Agora preencha os Dados de Pagamento 🤩",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      props.setKeepGoingE(false);
    }
  }

  useEffect(() => {
    async function getCities() {
      if (state) {
        try {
          const result = await sendRequest.getAddress({ state: state });
          //console.log(result);
          if (result && result.cities) {
            let array = [];

            result.cities.forEach((city) => {
              array.push({
                text: city.text,
                value: city.text,
              });
            });

            setCityOptions(array);
          }
        } catch (e) {
          //console.log(e);
        }

        if (state && (zipcode.length < 8 || zipcode.length > 8)) {
          setCityDisabled(false);
        }
      } else {
        setCityDisabled(true);
      }
    }

    async function getAddress() {
      if (zipcode.length === 8) {
        try {
          let result = await sendRequest.getAddress({ zipcode: zipcode });

          if (result && result.address) {
            if (result.address.street) {
              setStreet(result.address.street);
              setStreetDisabled(true);
            }

            if (result.address.state && result.address.state.text) {
              setState(result.address.state.text);
              setStateDisabled(true);
              getCities();
            }

            if (result.address.neighborhood) {
              setNeighborhood(result.address.neighborhood);
              setNeighborhoodDisabled(true);
            }

            if (result.address.city && result.address.city.text) {
              setCity(result.address.city.text);
              setCityDisabled(true);
            }
          } else {
            setStreetDisabled(false);
            setCityDisabled(false);
            setStateDisabled(false);
            setNeighborhoodDisabled(false);
          }
        } catch (e) {
          //console.log(e);
        }
      } else {
        setStreet("");
        setCity("");
        setState("");
        setNeighborhood("");

        setStreetDisabled(false);
        state && (zipcode.length < 8 || zipcode.length > 8)
          ? setCityDisabled(false)
          : setCityDisabled(true);
        setStateDisabled(false);
        setNeighborhoodDisabled(false);
      }
    }

    getAddress();
  }, [zipcode, setCity, setNeighborhood, setState, setStreet, state]);

  useEffect(() => {
    async function getCities() {
      if (state) {
        try {
          const result = await sendRequest.getAddress({ state: state });
          //console.log(result);
          if (result && result.cities) {
            let array = [];

            result.cities.forEach((city) => {
              array.push({
                text: city.text,
                value: city.text,
              });
            });

            setCityOptions(array);
          }
        } catch (e) {
          //console.log(e);
        }

        if (state && (zipcode.length < 8 || zipcode.length > 8)) {
          setCityDisabled(false);
        }
      } else {
        setCityDisabled(true);
      }
    }

    getCities();
  }, [state, zipcode]);

  return (
    <Container>
      <Paper>
        <InputsContainer>
          <Input
            title="CEP *"
            placeholder="Digite o Seu CEP"
            half={true}
            contrast={true}
            onChange={setZipcode}
            value={zipcode}
            setError={setZipcodeError}
            zipcodeValidation={true}
            number={true}
            check={check}
          />

          <Input
            title="Endereço *"
            placeholder="Digite o Seu Endereço"
            half={true}
            onChange={setStreet}
            value={street}
            disabled={streetDisabled}
            contrast={streetDisabled}
            setError={setStreetError}
            emptyValidation={true}
            check={check}
          />
        </InputsContainer>
        <InputsContainer>
          <Combobox
            title="Estado *"
            placeholder="Digite o Seu Estado"
            half={true}
            onChange={setState}
            value={state}
            disabled={stateDisabled}
            contrast={stateDisabled}
            statesOptions={true}
            setError={setStateError}
            emptyValidation={true}
            check={check}
          />

          <Combobox
            title="Cidade *"
            placeholder="Digite a Sua Cidade"
            half={true}
            onChange={setCity}
            value={city}
            disabled={cityDisabled}
            contrast={cityDisabled}
            options={cityOptions}
            setError={setCityError}
            emptyValidation={true}
            check={check}
          />
        </InputsContainer>
        <InputsContainer>
          <Input
            title="Número *"
            placeholder="Digite o Seu Número"
            half={true}
            number={true}
            onChange={setNumber}
            value={number}
            setError={setNumberError}
            emptyValidation={true}
            check={check}
          />
          <Input
            title="Bairro *"
            placeholder="Digite o Seu Bairro"
            half={true}
            onChange={setNeighborhood}
            value={neighborhood}
            disabled={neighborhoodDisabled}
            contrast={neighborhoodDisabled}
            setError={setNeighborhoodError}
            emptyValidation={true}
            check={check}
          />
        </InputsContainer>

        <Input
          title="Complemento "
          placeholder="Digite o Seu Complemento"
          half={false}
          onChange={setComplement}
          value={complement}

          /* emptyValidation={true}
          setError={setComplementError}
          check={check} */
        />

        <Button
          text="Próximo Passo"
          half={false}
          onClick={() => {
            setCheck(true);
            next();
          }}
        />
      </Paper>

      <Card />
    </Container>
  );
}

export default Address;

