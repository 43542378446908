import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: linear-gradient(267.07deg, #0C3167 0%, #3867AB 100%);
  border-top:1px solid #391761;

  width: 100%;
  height: 80px;

  font-family: 'Montserrat', sans-serif;

  @media (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    height: ${props => props.height}px;
  }
`;

export const LinkC = styled.a`
  height: 90%;
  font-family: 'Montserrat', sans-serif;
`;


export const Logo = styled.img`
  height: 90%;
  margin-left: 50px;
  font-family: 'Montserrat', sans-serif;

  @media(max-width: 2560px) {
    margin-left: var(--laptop-4k-side-margin);
  }

  @media(max-width: 1440px) {
    margin-left: var(--laptop-g-side-margin);
  }

  @media(max-width: 1024px) {
    margin-left: 0px;
  }

  @media(max-width: 768px) {
    margin-left: 0px;
  }

  @media(max-width: 425px) {
    margin-left: 0px;
  }
`;

export const Hambuguer = styled.a`
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.logo ? "center" : "flex-start"};
  font-family: 'Montserrat', sans-serif;
  height: 80px;

  @media(max-width: 2560px) {
    margin-right: ${props => props.links ? "var(--laptop-4k-side-margin)" : 0};
  }

  @media(max-width: 1440px) {
    margin-right: ${props => props.links ? "var(--laptop-g-side-margin)" : 0};
  }

  @media(max-width: 1050px) {
    width: 100%;
    justify-content:space-around;
    margin-right: 0px;
  }

  @media(max-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin-right: 0px;
  }

  @media(max-width: 425px) {
    margin-left: 0px;
  }
`;


export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 80px;
  font-family: 'Montserrat', sans-serif;

  a:visited {
    color:var(--ghostwhite);
  }

  a:active {
    color:var(--ghostwhite);
  }
`;

export const RedirectTo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  width: 150px;
  height: 80px;

  color: ${props => props.color ? props.color : "var(--ghostwhite)"};

  background-color:  ${props => props.background ? props.background : ""};
  cursor: pointer;

  &:hover{
    background-color:  #335d9c;
    color:var(--ghostwhite);
  }

  &:visited {
    color:var(--ghostwhite);
  }

  &:active {
    color:var(--ghostwhite);
  }

  @media (max-width: 1024px) {
    width: 25%;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const Redirect = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  width: 150px;
  height: 80px;

  color: ${props => props.color ? props.color : "var(--ghostwhite)"};

  background-color:  ${props => props.background ? props.background : ""};
  cursor: pointer;

  &:hover{
    background-color: #ededff;
    font-weight: 500;
    color: black;
  }

  &:visited {
    color: black;
  }

  &:active {
    color: black;
  }

  @media (max-width: 1024px) {
    width: 25%;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`;
