import React, { useEffect, useState } from "react";
//Style Components ===========================================================
import {
  Container,
  WhoWeAre,
  Logo,
  Content,
  LogoContainer,
  IconsContainer,
  IconsCircle,
  Contacts,
  PhoneRow,
  Phone,
  PhoneColumn,
  Last,
} from "./style";

import Lottie from "react-lottie";

import {
  GrFacebookOption,
  GrYoutube,
  GrInstagram,
  GrLinkedin,
} from "react-icons/gr";

import { AiFillPhone, AiOutlineMail } from "react-icons/ai";

import clubeDasFloresLogo from "../../Assets/logo-clube-das-flores.png";
import zeloLogo from "../../Assets/zelo.png";

import ArrowUp from "../../Assets/arrow_up.json";
import sendRequest from "../../Services/sendRequest";

//Configuração do Ícone Animado
const defaultArrowUpIcon = {
  loop: true,
  autoplay: true,
  animationData: ArrowUp,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
//============================================================================

function Footer() {
  const [whoAreWe, setWhoAreWe] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [socialIcons, setSocialIcons] = useState([]);
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    async function getFooter() {
      try {
        await sendRequest.getFooter({}).then((response) => {
          setPhoneNumber(response.phoneNumber);
          setWhoAreWe(response.whoAreWe);
          setEmail(response.email);
          setSocialIcons(response.socialIcons);
          setLogos(response.logos);
        });
      } catch (err) {
        ////console.log(`Error trying to fetch FooterData: ${err}`);
      }
    }

    getFooter();
  }, []);

  return (
    <>
      <Container id="contato">
        <WhoWeAre>
          <LogoContainer>
            {logos ? (
              <>
                <Logo
                  key={logos[0]}
                  src={logos && logos[0]}
                  style={{ marginRight: 30 }}
                />
                <Logo key={logos[1]} src={logos && logos[1]} />
              </>
            ) : (
              <>
                <Logo src={zeloLogo} style={{ marginRight: 30 }} />
                <Logo src={clubeDasFloresLogo} />
              </>
            )}
          </LogoContainer>
          <Content>
            {whoAreWe ? (
              whoAreWe
            ) : (
              <p>
                O Grupo Zelo é uma das maiores e mais sólidas empresas do Brasil
                no segmento de death care que oferece serviços integrados de
                assistência funerária. Pensando em uma forma de homenagear seu
                ente querido, o Grupo Zelo criou o Clube das Flores para que
                você possa se fazer presente sem precisar ir pessoalmente ao
                Cemitério, respeitando o seu tempo e oferecendo a comodidade que
                você merece. Serão colocados arranjos florais durante o ano em
                datas comemorativas como Finados, Dia das Mães, Dia dos Pais ou
                em datas específicas selecionadas por você. Após a colocação dos
                arranjos, enviaremos uma foto com a comprovação para o seu
                e-mail ou whatsapp.
              </p>
            )}
          </Content>
        </WhoWeAre>

        <Contacts>
          <PhoneColumn>
            <PhoneRow style={{ borderBottom: "1px solid black" }}>
              <AiFillPhone size={20} color="#3867AB" />
              <Phone>{phoneNumber && phoneNumber}</Phone>
            </PhoneRow>
            <PhoneRow>
              <AiOutlineMail size={20} color="#3867AB" />
              <Phone>{email && email}</Phone>
            </PhoneRow>
          </PhoneColumn>

          <IconsContainer>
            {socialIcons &&
              socialIcons.map((icon) => {
                if (icon.name === "facebook") {
                  return (
                    <IconsCircle key={icon.url} href={icon.url}>
                      <GrFacebookOption size={20} color="#3867AB" />
                    </IconsCircle>
                  );
                }
                if (icon.name === "youtube") {
                  return (
                    <IconsCircle key={icon.url} href={icon.url}>
                      <GrYoutube size={20} color="#3867AB" />
                    </IconsCircle>
                  );
                }
                if (icon.name === "instagram") {
                  return (
                    <IconsCircle key={icon.url} href={icon.url}>
                      <GrInstagram size={20} color="#3867AB" />
                    </IconsCircle>
                  );
                }
                if (icon.name === "linkedin") {
                  return (
                    <IconsCircle key={icon.url} href={icon.url}>
                      <GrLinkedin size={20} color="#3867AB" />
                    </IconsCircle>
                  );
                }
                return null;
              })}
            <IconsCircle>
              <Lottie
                options={defaultArrowUpIcon}
                height={20}
                width={20}
                isClickToPauseDisabled
              />
            </IconsCircle>
          </IconsContainer>
        </Contacts>
      </Container>
      <Last>Copyright © {new Date().getFullYear()} Grupo Zelo</Last>
    </>
  );
}

export default Footer;

