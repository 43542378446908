import React from "react";

import { Container, Text } from "./style";
import { Checkbox } from "@chakra-ui/react";

function CheckboxComponent(props) {
  let text =
    "Autorizo contato do Grupo Zelo para prestação desse serviço. Meus dados serão tratados de acordo com o artigo 7, V da Lei Geral de Proteção de Dados (Lei 13.709/18). Qualquer dúvida acesse nossa.";

  return (
    <Container half={props.half} interspace={props.interspace}>
      <Checkbox
        colorScheme="blue"
        isChecked={props.value}
        onChange={(e) => props.onChange(e.target.checked)}
      />
      <Text interspace={props.interspace}>{text}</Text>
    </Container>
  );
}

export default CheckboxComponent;

