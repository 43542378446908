import { sydleApi } from "./api";

const sendRequest = {
  purchase: async (params) => {
    try {
      const result = await sydleApi.post(
        "/clubeDasFlores/fachadaSiteClubeDasFlores/purchase",
        params
      );

      if (result.status === 200) {
        return result;
      }
    } catch (error) {
      throw error.response.data.generalMessages[0].text;
    }
  },
  getAddress: async (params) => {
    const result = await sydleApi
      .post("/clubeDasFlores/fachadaSiteClubeDasFlores/getAddress", params)
      .then((response) => {
        if (response.status === 200) {
          // Possíveis campos retornados pelo método
          const address = response.data.address;
          const cities = response.data.cities;
          const states = response.data.states;

          return { address, cities, states };
        }
      })
      .catch((err) => {
        return err;
      });

    return result;
  },

  getHome: async (params) => {
    const result = await sydleApi
      .post("/clubeDasFlores/fachadaSiteClubeDasFlores/getHome", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data.home;
        }
      })
      .catch((err) => {
        //console.log(`Erro ao realizar requisição getHome: ${err}`)
        return err;
      });

    return result;
  },

  getPlan: async (params) => {
    const result = await sydleApi
      .post("/clubeDasFlores/fachadaSiteClubeDasFlores/getPlan", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data.plan;
        }
      })
      .catch((err) => {
        //console.log(`Erro ao realizar requisição getPlan: ${err}`)
        return err;
      });
    return result;
  },

  getNavbar: async (params) => {
    const result = await sydleApi
      .post("/clubeDasFlores/fachadaSiteClubeDasFlores/getNavbar", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((err) => {
        //console.log(`Erro ao realizar requisição getNavbar: ${err}`)
        return err;
      });
    return result;
  },

  getFooter: async (params) => {
    const result = await sydleApi
      .post("/clubeDasFlores/fachadaSiteClubeDasFlores/getFooter", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data.footer;
        }
      })
      .catch((err) => {
        //console.log(`Erro ao realizar requisição getFooter: ${err}`)
        return err;
      });
    return result;
  },

  getCheckout: async (params) => {
    const result = await sydleApi
      .post("/clubeDasFlores/fachadaSiteClubeDasFlores/getCheckout", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data.checkout;
        }
      })
      .catch((err) => {
        //console.log(`Erro ao realizar requisição getCheckout: ${err}`)
        return err;
      });
    return result;
  },

  validatePlan: async (params) => {
    const result = await sydleApi
      .post("/clubeDasFlores/fachadaSiteClubeDasFlores/validatePlan", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data.success;
        }
      })
      .catch((err) => {
        //console.log(`Erro ao realizar requisição validatePlan: ${err}`)
        return err;
      });
    return result;
  },
  registerPayment: async (params) => {
    const result = await sydleApi
      .post("clubeDasFlores/fachadaSiteClubeDasFlores/registerPayment", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data.success;
        }
      })
      .catch((err) => {
        //console.log(`Erro ao realizar requisição validatePlan: ${err}`)
        return err;
      });
    return result;
  },
};

export default sendRequest;

