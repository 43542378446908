import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8992b3;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;

  width: ${(props) => (props.half ? 48 : 100)}%;
  height: 100%;

  margin-top: ${(props) => (props.half ? 0 : 20)}px;

  padding: 10px;

  background: linear-gradient(267.07deg, #0c3167 0%, #3867ab 100%);

  &:hover {
    background: #3867ab;
  }
`;

export const Text = styled.div`
  width: 100%;
  font-family: "Montserrat", sans-serif;

  color: var(--ghostwhite);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`;
