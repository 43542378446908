import React from "react";
import { Container } from './style'

function FormDivider({title, payload}) {
  return (
    <Container>
      <p>{title}</p>
    </Container>
  )
}

export default FormDivider