import styled from "styled-components";
import { Tabs } from "@chakra-ui/react";

export const Container = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;

  background-color:var(--ghostwhite);

  padding-top: 50px;
  padding-bottom: 5%;

  width: 100%;
  height: 100%;

  @media(max-width: 2560px) {
    padding-left: var(--laptop-4k-side-margin);
    padding-right: var(--laptop-4k-side-margin);
  }

  @media(max-width: 1440px) {
    padding-left: var(--laptop-g-side-margin);
    padding-right: var(--laptop-g-side-margin);
  }

  @media(max-width: 1024px) {
    padding-left: var(--laptop-p-side-margin);
    padding-right: var(--laptop-p-side-margin);
  }

  @media(max-width: 768px) {
    padding-left: var(--tablet-side-margin);
    padding-right: var(--tablet-side-margin);
  }

  @media(max-width: 425px) {
    padding-left: var(--movel-g-side-margin);
    padding-right: var(--movel-g-side-margin);
  }
`;

export const Table = styled(Tabs)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;

  width: 100%;
  height: 100%;
`;

