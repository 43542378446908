import styled from "styled-components";

export const Container = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;

  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;

  @media(max-width: 1024px) {
    justify-content: center;
  }

  @media(max-width: 768px) {
    justify-content: center;
  }

  @media(max-width: 425px) {
    justify-content: center;
  }
`;

export const Paper = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: white;
  border-radius:10px;
  font-family: 'Montserrat', sans-serif;

  border: 1px solid #d5dbeb;

  height: 100%;

  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;

  @media(max-width: 2560px) {
    width: 60%;
  }

  @media(max-width: 2000px) {
    width: 60%;
  }

  @media(max-width: 1440px) {
    width: 60%;
  }

  @media(max-width: 1024px) {
    width: 100%;
  }

  @media(max-width: 768px) {
    width: 100%;
  }

  @media(max-width: 425px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  width: 100%;

  margin-top: 30px;
  color: #4D8076;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
`;

export const SubTitle = styled.div`
  width: 100%;

  margin-top: 20px;
  color: #4D8076;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;


export const Billet = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
  color: #3867AB;
  background-color: #f5f8ff;

  border: 1px solid #3867AB;
  border-radius: 10px;

  padding-left: 20px;
  padding-right: 10px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size:18px;
  line-height: 24px;
  text-align: center;
`;

