import { paymentApi } from "./api";

const sendRequestGatewayPayment = {
  getCheckoutBoleto: async (params) => {
    const result = await paymentApi
      .post("/filial-boleto/", params)
      .then((response) => {
        if (response.status === 200) {
          return response.data.checkout;
        }
      })
      .catch((err) => {
        return err;
      });
    return result;
  },
  postCheckoutCard: async (payload, filial) => {
    try {
      const result = await paymentApi.post(`filial-cartao/${filial}`, payload);
      return result;
    } catch (error) {
      throw error;
    }
  },
  postCheckoutBankSlip: async (payload, filial) => {
    try {
      const result = await paymentApi.post(`filial-boleto/${filial}`, payload, {
        responseType: "blob",
      });
      return result;
    } catch (error) {
      throw error;
    }
  },
};

export default sendRequestGatewayPayment;

