import styled from "styled-components";

export const Container = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;

  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;

  @media(max-width: 1024px) {
    justify-content: center;
  }

  @media(max-width: 768px) {
    justify-content: center;
  }

  @media(max-width: 425px) {
    justify-content: center;
  }
`;

export const Paper = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color: white;
  border-radius:10px;

  border: 1px solid #d5dbeb;
  font-family: 'Montserrat', sans-serif;

  height: 100%;

  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;

  @media(max-width: 2560px) {
    width: 60%;
  }

  @media(max-width: 2000px) {
    width: 60%;
  }

  @media(max-width: 1440px) {
    width: 60%;
  }

  @media(max-width: 1024px) {
    width: 100%;
  }

  @media(max-width: 768px) {
    width: 100%;
  }

  @media(max-width: 425px) {
    width: 100%;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  font-family: 'Montserrat', sans-serif;

  margin-top: 20px;
`;

export const Card = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color: blue;
  background-color: white;
  font-family: 'Montserrat', sans-serif;

  border-radius:10px;
  border: 1px solid #d5dbeb;

  width: 500px;

  padding: 30px;

  @media(max-width: 2560px) {
    width: 30%;
  }

  @media(max-width: 1900px) {
    width: 30%;
  }

  @media(max-width: 1440px) {
    width: 30%;
  }

  @media(max-width: 1024px) {
    width: 100%;
    margin-top: 50px;
  }

  @media(max-width: 768px) {
    width: 100%;
    margin-top: 50px;
  }

  @media(max-width: 425px) {
    width: 100%;
    margin-top: 50px;
  }
`;

export const Image = styled.div`
  border-radius: 10px;

  width: 100%;
  max-width: 610px;
  height: 150px;
  font-family: 'Montserrat', sans-serif;

  margin-top: 10px;
  margin-bottom: 10px;

  background-color: #f5f8ff;
  border: 1px solid #d5dbeb;

`;

export const Title = styled.div`
  width: 100%;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`;

export const SubTitle = styled.div`
  width: 100%;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`;

export const Partial = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 10px;

  width: 100%;

  padding-top: 10px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
`;

export const SubLine = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;


  border-bottom-left-radius: 5px;
  border-left: 1px solid #d5dbeb;
  border-bottom: 1px solid #d5dbeb;

  width: 100%;

  padding: 10px;

  margin-left: 60px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
`;



export const Up = styled.div`
font-family: 'Montserrat', sans-serif;
font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  text-align: justify;

  margin-left: 5px;
  margin-right: 5px;

`;

export const TotalTitle = styled.div`
font-family: 'Montserrat', sans-serif;
font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  text-align: justify;
`;

export const Line = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  width: 100%;
  font-family: 'Montserrat', sans-serif;

  margin-right: -60px;

`;

export const TotalBox = styled.div`
  display: flex;
  flex-direction:column;
  align-items: flex-end;
  justify-content: flex-end;

  padding: 10px;
  padding-top: 0px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  margin-top: 20px;

  background-color: #f5f8ff;
  border: 1px solid #d5dbeb;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

`;

export const TotalValue = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 10px;

  width: 100%;

  padding-top: 10px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
`;

export const PaymentForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  width: 100%;

  margin-top: 20px;
  margin-bottom: -20px;

  background-color: #f5f8ff;
  border: 1px solid #d5dbeb;

  font-style: italic;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
`;
