//React =============================================================================
import React, { useEffect, useState } from "react";
//Style =============================================================================
import { InputContainer, Input, InputTitle } from "./style";
//Libraries =========================================================================
import validator from "validator";
import { cpf } from "cpf-cnpj-validator";
//===================================================================================

function InputComponent(props) {
  const [error, setError] = useState(false);
  const [optionsComponent, setOptionsComponent] = useState([]);

  let { statesOptions, options, placeholder, check, value } = props;

  useEffect(() => {
    const states = [
      { name: "Acre", initials: "AC", _id: "590b7f3ef5ca5307c8e841c1" },
      { name: "Alagoas", initials: "AL", _id: "590b7f44f5ca5307c8e841c2" },
      { name: "Amapá", initials: "AP", _id: "590b7f44f5ca5307c8e841c3" },
      { name: "Amazonas", initials: "AM", _id: "590b7f44f5ca5307c8e841c4" },
      { name: "Bahia", initials: "BA", _id: "590b7f44f5ca5307c8e841c5" },
      { name: "Ceará", initials: "CE", _id: "590b7f44f5ca5307c8e841c6" },
      {
        name: "Distrito Federal",
        initials: "DF",
        _id: "590b7f44f5ca5307c8e841c7",
      },
      { name: "Espírito Santo", initials: "ES", _id: "590b7f44f5ca5307c8e841c8" },
      { name: "Goiás", initials: "GO", _id: "590b7f44f5ca5307c8e841c9" },
      { name: "Maranhão", initials: "MA", _id: "590b7f44f5ca5307c8e841ca" },
      { name: "Mato Grosso", initials: "MT", _id: "590b7f44f5ca5307c8e841cb" },
      {
        name: "Mato Grosso do Sul",
        initials: "MS",
        _id: "590b7f44f5ca5307c8e841cc",
      },
      { name: "Minas Gerais", initials: "MG", _id: "590b7f44f5ca5307c8e841cd" },
      { name: "Pará", initials: "PA", _id: "590b7f44f5ca5307c8e841ce" },
      { name: "Paraíba", initials: "PB", _id: "590b7f44f5ca5307c8e841cf" },
      { name: "Paraná", initials: "PR", _id: "590b7f44f5ca5307c8e841d0" },
      { name: "Pernambuco", initials: "PE", _id: "590b7f44f5ca5307c8e841d1" },
      { name: "Piauí", initials: "PI", _id: "590b7f44f5ca5307c8e841d2" },
      { name: "Rio de Janeiro", initials: "RJ", _id: "590b7f44f5ca5307c8e841d3" },
      {
        name: "Rio Grande do Norte",
        initials: "RN",
        _id: "590b7f44f5ca5307c8e841d4",
      },
      {
        name: "Rio Grande do Sul",
        initials: "RS",
        _id: "590b7f44f5ca5307c8e841d5",
      },
      { name: "Rondônia", initials: "RO", _id: "590b7f44f5ca5307c8e841d6" },
      { name: "Roraima", initials: "RR", _id: "590b7f44f5ca5307c8e841d7" },
      { name: "Santa Catarina", initials: "SC", _id: "590b7f44f5ca5307c8e841d8" },
      { name: "São Paulo", initials: "SP", _id: "590b7f44f5ca5307c8e841d9" },
      { name: "Sergipe", initials: "SE", _id: "590b7f44f5ca5307c8e841da" },
      { name: "Tocantins", initials: "TO", _id: "590b7f44f5ca5307c8e841db" },
    ];

    let array = [];

    if (statesOptions) {
      array.push(<option value="">Selecione um Estado</option>);

      states.forEach((state) => {
        array.push(
          <option value={state.name}>
            {state.name} - {state.initials}
          </option>
        );
      });

      setOptionsComponent(array);
    } else if (options && options.length > 0) {
      array.push(<option value="">{placeholder}</option>);

      options.forEach((option) => {
        array.push(<option value={option.value}>{option.text}</option>);
      });

      setOptionsComponent(array);
    }
  }, [props, statesOptions, options, placeholder]);

  useEffect(() => {
    function validationInside(value) {
      //Validação de Sobrenome
      if (props.lastNameValidation) {
        let space = value.indexOf(" ");

        if (space !== -1 && value[space + 1] && value[space + 2]) {
          setError(false);
        } else {
          setError(true);
        }
      }

      //Validação se está vazio
      if (props.emptyValidation) {
        if (value && value !== "" && value !== " ") {
          setError(false);
          props.setError(false);
        } else {
          setError(true);
          props.setError(true);
        }
      }

      //Verificar se é um cartão de crédito
      if (props.creditCardValidation) {
        if (validator.isCreditCard(value)) {
          setError(false);
        } else {
          setError(true);
        }
      }

      //Validação de Espaço no Início do Input e se é número inteiro
      if (value[0] !== " " && props.cvcDigitsValidation) {
        if (validator.isInt(value)) {
          props.onChange(value);
        }
      }

      //Validação se é um e-mail vaĺido
      if (props.emailValidation && validator.isEmail(value)) {
        setError(false);
      } else if (props.emailValidation) {
        setError(true);
      }

      //Valida se uma string é igual a outra
      if (props.equalValidation && props.equalValidation === value) {
        setError(false);
      } else if (props.equalValidation) {
        setError(true);
      }

      //Validador de CPF
      if (props.cpfValidation && cpf.isValid(value)) {
        setError(false);
      } else if (props.cpfValidation) {
        setError(true);
      }

      //Valida se é um Telefone
      if (props.phoneValidation && validator.isMobilePhone(value, "pt-BR")) {
        setError(false);
      } else if (props.phoneValidation) {
        setError(true);
      }

      //Validação de Espaço no Início do Input
      if (value[0] !== " ") {
        //Valida Se é um Número, quando for CVC
        if (
          props.cvcDigitsValidation &&
          (validator.isInt(value) || value === "")
        ) {
          props.onChange(value);
        }

        if (!props.cvcDigitsValidation) {
          props.onChange(value);
        }
      }
    }

    if (value) validationInside(value);
  }, [props, check, value]);

  function validation(value) {
    //Validação de Sobrenome
    if (props.lastNameValidation) {
      let space = value.indexOf(" ");

      if (space !== -1 && value[space + 1] && value[space + 2]) {
        setError(false);
      } else {
        setError(true);
      }
    }

    //Validação se está vazio
    if (props.emptyValidation) {
      if (value && value !== "" && value !== " ") {
        setError(false);
        props.setError(false);
      } else {
        setError(true);
        props.setError(true);
      }
    }

    //Verificar se é um cartão de crédito
    if (props.creditCardValidation) {
      if (validator.isCreditCard(value)) {
        setError(false);
      } else {
        setError(true);
      }
    }

    //Validação de Espaço no Início do Input e se é número inteiro
    if (value[0] !== " " && props.cvcDigitsValidation) {
      if (validator.isInt(value)) {
        props.onChange(value);
      }
    }

    //Validação se é um e-mail vaĺido
    if (props.emailValidation && validator.isEmail(value)) {
      setError(false);
    } else if (props.emailValidation) {
      setError(true);
    }

    //Valida se uma string é igual a outra
    if (props.equalValidation && props.equalValidation === value) {
      setError(false);
    } else if (props.equalValidation) {
      setError(true);
    }

    //Validador de CPF
    if (props.cpfValidation && cpf.isValid(value)) {
      setError(false);
    } else if (props.cpfValidation) {
      setError(true);
    }

    //Valida se é um Telefone
    if (props.phoneValidation && validator.isMobilePhone(value, "pt-BR")) {
      setError(false);
    } else if (props.phoneValidation) {
      setError(true);
    }

    //Validação de Espaço no Início do Input
    if (value[0] !== " ") {
      //Valida Se é um Número, quando for CVC
      if (
        props.cvcDigitsValidation &&
        (validator.isInt(value) || value === "")
      ) {
        props.onChange(value);
      }

      if (!props.cvcDigitsValidation) {
        props.onChange(value);
      }
    }
  }

  return (
    <InputContainer half={props.half}>
      <InputTitle
        error={
          check && (!value || value === "") ? true : error
        }
      >
        {props.title}
      </InputTitle>
      <Input
        placeholder={placeholder}
        contrast={props.contrast}
        onChange={(e) => validation(e.target.value)}
        value={value}
        maxLength={props.cvcDigitsValidation ? props.cvcDigitsValidation : null}
        type={props.number ? "number" : "text"}
        error={
          check && (!value || value === "") ? true : error
        }
        disabled={props.disabled}
      >
        {optionsComponent}
      </Input>
    </InputContainer>
  );
}

export default InputComponent;

