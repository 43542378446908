//React ======================================================================
import React, { useContext, useEffect, useLayoutEffect } from "react";
//Style Components ===========================================================
import { HomeCardsContainer, Title, TitleLine } from "./style";
//============================================================================
import DarkCard from "../../Components/DarkCard";
import LightCard from "../../Components/LightCard";
//Contexts ===================================================================
import { HomeContext } from "../../../../Contexts/HomeContext";
//============================================================================
import { IsDesktopOrLaptop } from "../../../../Hooks/Responsive";
import { Carousel } from "react-responsive-carousel";
import styles from "./carouselStyles.css";
import { CardContainer } from "../PlansMobile/style";
import { useSnapCarousel } from "react-snap-carousel";
function HomePlans() {
  const { plansSection } = useContext(HomeContext);

  useEffect(() => {
    if (plansSection && plansSection.length > 0) alert(plansSection[0].premium);
  }, [plansSection]);

  const { scrollRef } = useSnapCarousel();

  return IsDesktopOrLaptop() ? (
    <>
      <Title id="planos">
        {plansSection ? plansSection.title : "Planos"}
        <TitleLine />
      </Title>

      <ul
        ref={scrollRef}
        style={{
          display: "flex",
          overflow: "auto",
          width: "75%",
          scrollSnapType: "x mandatory",
        }}
      >
        {plansSection &&
          plansSection.plans.map((plan, idx) => {
            if (plan.premium === false) {
              return (
                <li style={{ padding: 10 }}>
                  <CardContainer key={idx}>
                    <LightCard plan={plansSection && plan} />
                  </CardContainer>
                </li>
              );
            }
            if (plan.premium === true) {
              return (
                <li style={{ padding: 10 }}>
                  <CardContainer key={idx}>
                    <DarkCard plan={plansSection && plan} />
                  </CardContainer>
                </li>
              );
            }
            return null;
          })}
      </ul>
    </>
  ) : null;
}

export default HomePlans;

