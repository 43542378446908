//==========================================================================================
import { useMediaQuery } from "react-responsive";
//==========================================================================================
export const IsDesktopOrLaptop = () => useMediaQuery({ query: "(min-width: 1024px)" });
export const IsDesktopOrLaptopG = () => useMediaQuery({ query: "(min-width: 1400px)" });
export const IsTabletOrMobile = () => useMediaQuery({ query: "(max-width: 1023px)" });
export const IsBigScreen = () => useMediaQuery({ query: "(min-width: 1824px)" });
export const IsPortrait = () => useMediaQuery({ query: "(orientation: portrait)" });
export const IsRetina = () => useMediaQuery({ query: "(min-resolution: 2dppx)" });

export const Is4k = () => useMediaQuery({ query: "(max-width: 2560px)" });
export const IsLaptopG = () => useMediaQuery({ query: "(max-width: 1440px)" });
export const IsLaptopP = () => useMediaQuery({ query: "(max-width: 1024px)" });
export const Mobile = () => useMediaQuery({ query: "(max-width: 425px)" });

//==========================================================================================
