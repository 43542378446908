import { useContext } from "react";

import { Carousel } from "react-responsive-carousel";
import styles from "./carouselStyles.css";

import { IsDesktopOrLaptop } from "../../../../Hooks/Responsive";
import { HomeContext } from "../../../../Contexts/HomeContext";

//Style Components ===========================================================
import { Container, CardContainer, Title, TitleLine } from "./style";
//============================================================================
import DarkCard from "../../Components/DarkCard";
import LightCard from "../../Components/LightCard";
//============================================================================

function HomePlans() {
  const { plansSection } = useContext(HomeContext);

  return !IsDesktopOrLaptop() ? (
    <>
      <Title id="planos">
        Planos
        <TitleLine />
      </Title>
      <Container>
        <Carousel
          style={styles}
          showThumbs={false}
          swipeable={true}
          emulateTouch={true}
          showStatus={false}
          showIndicators={false}
          showArrows={false}
        >
          {plansSection &&
            plansSection.plans.map((plan) => {
              if (plan.premium === false) {
                return (
                  <CardContainer>
                    <LightCard plan={plansSection && plan} />
                  </CardContainer>
                );
              }
              if (plan.premium === true) {
                return (
                  <CardContainer>
                    <DarkCard plan={plansSection && plan} />
                  </CardContainer>
                );
              }
              return null;
            })}
        </Carousel>
      </Container>
    </>
  ) : null;
}

export default HomePlans;

