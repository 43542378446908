import styled from 'styled-components';

export const Container = styled.div`
  grid-column: 2/3;
  position: relative;
  font-family: 'Montserrat', sans-serif;

  @media (max-width: 1500px) {
    grid-column: 1/2;
  }
`;


export const Pricing = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 30px;
  margin-bottom: 85%;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d5dbeb;
  font-family: 'Montserrat', sans-serif;

  h1 {
    color: #363636;
    margin-bottom: 25px;
    font-size: 1.5rem;
  }

  p b {
    color: var(--hydrangea-blue);
    font-size: 2rem;
  }

  .checkbox {
    display: flex;
    align-items: center;

    label {
      margin-left: 10px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 99px;
    background-color: var(--light-blue);

    left: 0;
    top: 50%;
    transform: translateX(-60%) translateY(-50%);

    @media (max-width: 1500px) {
      top: 0;
      left: 50%;
      transform: translateY(-60%) translateX(-50%);
    }
  }

  @media (max-width: 1500px) {
    margin-bottom: 380px;
  }

  @media (max-width: 320px) {
    p b {
      font-size: 1.5rem;
    }
  }
`;


export const PricingDivider = styled.div`
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  font-family: 'Montserrat', sans-serif;

`;


export const PlanOptionContainer = styled.div`
  div {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;

    .value {
      font-weight: 600;
    }
  }
`;


export const DatesContainer = styled.div`

  p {
    margin-top: 5px;
    font-family: 'Montserrat', sans-serif;

  }
`;


export const BuyButton = styled.button`
  padding: 10px 0;
  margin-top: 20px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: var(--hydrangea-blue);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.5s;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background-color: var(--dark-blue);
    letter-spacing: 2px;
  }
`;


// TESTIMONIAL STYLE
export const Testimonial = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #d5dbeb;
  font-family: 'Montserrat', sans-serif;

`;
