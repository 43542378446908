import sendRequest from "../../../../../Services/sendRequest";

export class Purchase {
  contractId = "";
  invoiceId = "";
  constructor(requisitionData) {
    this.requisitionData = requisitionData;
  }
  async execute() {}
  async createSydleContract() {
    try {
      const { data: responseCreateSydleContract } = await sendRequest.purchase(
        this.requisitionData
      );

      this.contractId = responseCreateSydleContract.contractId;
      this.invoiceId = responseCreateSydleContract.invoiceId;
    } catch (error) {
      this.possuiErroSydle = error;
      return;
    }
  }
}

