import styled from "styled-components";

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  font-family: 'Montserrat', sans-serif;

  margin-top: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;

  width: ${props => props.half ? 48 : 100}%;
  height: 60px;

  margin-top: ${props => props.half ? 0 : 20}px;

`;

export const InputTitle = styled.h4`
  color: ${props => props.error ? "#ba000d" : "var(--hydrangea-blue)"};
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
`;

export const ContainerI = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;

  width: 100%;
`;

export const Input = styled.input`
  width: 50%;

  border-radius: 5px;

  border-top-right-radius: ${props => props.one ? 0 : 5}px;
  border-bottom-right-radius: ${props => props.one ? 0 : 5}px;

  border-top-left-radius: ${props => props.one ? 5 : 0}px;
  border-bottom-left-radius: ${props => props.one ? 5 : 0}px;

  border: 1px solid ${props => props.error ? "#ba000d" : "#8992B3"};
  border-left: ${props => !props.one ? 0 : 1}px solid ${props => props.error ? "#ba000d" : "#8992B3"};

  padding: 10px;

  background-color: ${props => props.contrast ? "#F5F8FF" : "white"};

  color: var(--light-blue);
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  &:focus{
    outline: none;
  }
`;