import "semantic-ui-css/semantic.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { HomeProvider } from "./Contexts/HomeContext";
import { PlanProvider } from "./Contexts/PlanContext";
import { CheckoutProvider } from "./Contexts/CheckoutContext";
import Homepage from "./Pages/Home";
import Plano from "./Pages/Plano";
import Checkout from "./Pages/Checkout";
import GlobalStyle from "./globalStyles";

export const BASE_URL_FLOWERS_CLUB = "/";

function App() {
  return (
    <BrowserRouter>
      <HomeProvider>
        <PlanProvider>
          <CheckoutProvider>
            <Route path="/" component={Homepage} exact />
            <Route path="/:planId" component={Plano} exact />
            <Route path="/checkout/:planId" component={Checkout} exact />
            <GlobalStyle />
          </CheckoutProvider>
        </PlanProvider>
      </HomeProvider>
    </BrowserRouter>
  );
}

export default App;

