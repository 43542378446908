import { AccordionButton, AccordionPanel } from "@chakra-ui/react";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;

  background-color:  var(--very-light-blue);

  width: 100%;

  padding-bottom:50px ;

  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;

  @media(max-width: 2560px) {
    padding-left: var(--laptop-4k-side-margin);
    padding-right: var(--laptop-4k-side-margin);
  }

  @media(max-width: 1950px) {
    padding-left: var(--laptop-g-side-margin);
    padding-right: var(--laptop-g-side-margin);
  }

  @media(max-width: 1400px) {
    padding-left: var(--laptop-g-side-margin);
    padding-right: var(--laptop-g-side-margin);
  }

  @media(max-width: 1300px) {
    padding-left: var(--laptop-p-side-margin);
    padding-right: var(--laptop-p-side-margin);
  }

  @media(max-width: 1024px) {
    padding-left: var(--laptop-p-side-margin);
    padding-right: var(--laptop-p-side-margin);
  }

  @media(max-width: 768px) {
    padding-left: var(--tablet-side-margin);
    padding-right: var(--tablet-side-margin);
  }

  @media(max-width: 425px) {
    padding-left: var(--movel-g-side-margin);
    padding-right: var(--movel-g-side-margin);
    padding-bottom: 20px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;

  background-color:  var(--very-light-blue);

  font-size: 24px;
  font-weight: 600;
  color: black;

  padding-top: 50px;
  padding-left: var(--laptop-4k-side-margin);

  width: 100%;
  height: 100%;

  @media(max-width: 2560px) {
    padding-left: var(--laptop-4k-side-margin);
    padding-right: var(--laptop-4k-side-margin);
  }

  @media(max-width: 1440px) {
    padding-left: var(--laptop-g-side-margin);
    padding-right: var(--laptop-g-side-margin);
  }

  @media(max-width: 1024px) {
    padding-left: var(--laptop-p-side-margin);
    padding-right: var(--laptop-p-side-margin);
  }

  @media(max-width: 768px) {
    padding-left: var(--tablet-side-margin);
    padding-right: var(--tablet-side-margin);
  }

  @media(max-width: 425px) {
    padding-left: var(--movel-g-side-margin);
    padding-right: var(--movel-g-side-margin);
  }
`;

export const TitleLine = styled.div`
  border-bottom: 5px solid var(--hydrangea-blue);
  height: 10px;
  width: 50px;
  font-family: 'Montserrat', sans-serif;

`;


export const Accordions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color:  var(--very-light-blue);

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  padding-top: 20px;

  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;

`;


export const StyledAccordionButton = styled(AccordionButton)`
  background: white;
  color: #323232;
  font-size: 16px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  margin-top: 20px;

  cursor: pointer;
  padding: 18px;
  border: none;
  outline: none;
  font-family: 'Montserrat', sans-serif;

  &:hover{
    background-color:#f7f7f7;
  }
`;


export const StyledPanel = styled(AccordionPanel)`
  background: #f2f2f2;

  color: #323232;
  font-size: 14px;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
  // !important para sobrescrever os styles da biblioteca
  padding: 20px !important;
`;


export const SkeletonContainer = styled.div`
  font-size: 50px;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif;

  width: 90%;
`;
