import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  font-family: 'Montserrat', sans-serif;

  background: linear-gradient(267.07deg, #0c3167 0%, #3867ab 100%);
  border-top: 1px solid #391761;

  width: 100%;
  height: 100%;

  padding-top: 40px;
  padding-bottom: 40px;

  @media (max-width: 2560px) {
    padding-left: var(--laptop-4k-side-margin);
    padding-right: var(--laptop-4k-side-margin);
  }

  @media (max-width: 1440px) {
    padding-left: var(--laptop-g-side-margin);
    padding-right: var(--laptop-g-side-margin);
  }

  @media (max-width: 1024px) {
    justify-content: center;
    padding-left: var(--laptop-p-side-margin);
    padding-right: var(--laptop-p-side-margin);
  }

  @media (max-width: 768px) {
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Last = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Montserrat', sans-serif;

  width: 100%;
  height: 100%;

  padding: 10px;
  color: var(--ghostwhite);
  background: #0c3167;
`;

export const WhoWeAre = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  font-family: 'Montserrat', sans-serif;

  width: 500px;
  height: 100%;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;

  text-align: justify;
  color: white;

  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
`;

export const Logo = styled.img`
  width: 100%;
  font-family: 'Montserrat', sans-serif;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: justify;
  color: white;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-family: "Montserrat", sans-serif;

  margin-top: 20px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  font-family: 'Montserrat', sans-serif;

  @media (max-width: 580px) {
    flex-direction: column;
    align-self: center;
    max-width: 200px;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;

  font-family: 'Montserrat', sans-serif;
`;

export const IconsCircle = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;
  border-radius: 50%;
  background-color: white;

  font-family: 'Montserrat', sans-serif;

  @media (max-width: 500px) {
    padding: 10px;
  }
`;

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  font-family: 'Montserrat', sans-serif;

  width: 500px;
  height: 100%;

  margin-top: 50px;

  @media (max-width: 350px) {
    width: 100%;
  }
`;

export const PhoneRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Montserrat', sans-serif;
  width: 100%;
  padding: 10px;
`;

export const PhoneColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  border-radius: 20px;

  width: 100%;

  background-color: var(--ghostwhite);
`;

export const Phone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #3867ab;

  @media (max-width: 350px) {
    font-size: 14px;
  }
`;
