import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #8992B3;
  border-radius: 5px;

  width: ${props => props.half ? 48 : 100}%;
  height: 100%;

  margin-top: ${props => props.half ? 0 : 20}px;

  color: var(--hydrangea-blue);
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;

  padding: 10px;
  padding-left: ${props => props.interspace ? 20 : 30}px;
`;

export const Text = styled.div`
  width: ${props => props.interspace ? 90 : 94}%;

  color: var(--hydrangea-blue);
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
`;