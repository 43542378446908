// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    box-sizing: border-box;
  }

  :root {
    --hydrangea-blue: #3867AB;
    --dark-blue: #0C3167;
    --blue: #d1d5ff;
    --light-blue: #8992B3;
    --very-light-blue: #ECEDF8;
    --yellow: #EDC385;
    --green: #007B41;

    --black: #323232;
    --ghostwhite: #f8f8ff;


    --laptop-large-side-margin: 200px;
    --laptop-side-margin: 100px;
    --mobile-side-margin: 29px;

    --laptop-4k-side-margin: 200px;
    --laptop-g-side-margin: 200px;
    --laptop-p-side-margin: 100px;
    --tablet-side-margin: 50px;
    --movel-g-side-margin: 25px;
    --movel-p-side-margin: 12,5px;

    --desktop: 1440px;
    --tablet: 768px;
    --mobilelg: 435px;
    --mobilemd: 375px;
    --mobilesm: 320px;

    scroll-behavior: smooth;
  }

`;

export default GlobalStyle;