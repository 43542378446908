import styled from "styled-components";

export const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  font-family: 'Montserrat', sans-serif;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: ${props => props.half ? 48 : 100}%;
  height: 60px;
  font-family: 'Montserrat', sans-serif;

  margin-top: ${props => props.half ? 0 : 20}px;

`;

export const InputTitle = styled.h4`
  color: ${props => props.error ? "#ba000d" : "var(--hydrangea-blue)"};
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;

  border-radius: 5px;
  border: 1px solid ${props => props.error ? "#ba000d" : "#8992B3"};

  padding: 10px;

  background-color: ${props => props.contrast ? "#F5F8FF" : "white"};

  color: var(--light-blue);
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  &:focus{
    outline: none;
  }
`;