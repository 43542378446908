import React from "react";
import { Container, Text } from "./style";

function ButtonComponent(props) {
  return (
    <Container half={props.half} onClick={props.onClick}>
      <Text>{props.text}</Text>
    </Container>
  );
}

export default ButtonComponent;

