//React =============================================================================
import React, { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

//Style =============================================================================
import { Container, Table } from "./style";
import { Mobile } from "../../Hooks/Responsive";
//Bibliotecas =======================================================================
import { ChakraProvider, TabList, TabPanels, TabPanel } from "@chakra-ui/react";
//Components ========================================================================
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import Tab from "./Components/Tab";
//Sections ==========================================================================
import PersonalData from "./Sections/01PersonalData";
import Address from "./Sections/02Address";
import Payment from "./Sections/03Payment";
import CompletedPurchase from "./Sections/04CompletedPurchase";
//Context ===========================================================================
import { CheckoutContext } from "../../Contexts/CheckoutContext";
import sendRequest from "../../Services/sendRequest";
//===================================================================================

function Checkout() {
  const history = useHistory();

  //Posição do Tabs =================================================================
  const [tabIndex, setTabIndex] = useState(0);
  //Prosseguir do Personal Data =====================================================
  const [keepGoingPD, setKeepGoingPD] = useState(false);
  //Prosseguir do Endereço ==========================================================
  const [keepGoingE, setKeepGoingE] = useState(false);
  //Prosseguir do Pagamento =========================================================
  const [keepGoingP, setKeepGoingP] = useState(false);
  //Carregamento da Página ==========================================================
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  //Posição do Tabs =================================================================
  const [shopping, setShopping] = useState(false);
  //Context Data ====================================================================
  const {
    setPlanID,
    setPlanName,
    setArrangement,
    setPlanImage,
    setBasePrice,
    setAdditionalPrice,
    setQuantity,
    setTotalPrice,
    setDates,
    setTextDates,
    setSpecialDay,
  } = useContext(CheckoutContext);
  //=================================================================================

  useEffect(() => {
    function getLocalStorageData() {
      let localPlanID = localStorage.getItem("plan_id")
        ? localStorage.getItem("plan_id")
        : undefined;

      let localPlanName = localStorage.getItem("planName")
        ? localStorage.getItem("planName").replaceAll('"', "")
        : undefined;

      let localArrangement = localStorage.getItem("selectedArrangementType")
        ? JSON.parse(localStorage.getItem("selectedArrangementType"))
        : undefined;

      let localPrice = localStorage.getItem("price")
        ? JSON.parse(localStorage.getItem("price"))
        : undefined;

      let quantity = localStorage
        .getItem("flowerArrangements")
        .replaceAll('"', "");
      let localQuantity = Number(quantity);

      let localBasePrice = Number(localPrice.plan);
      let localTypeAdditional = Number(localPrice.arrangementType);
      let localQuantityAdditional = Number(localPrice.flowerArrangements);

      let dates = localStorage.getItem("dates")
        ? JSON.parse(localStorage.getItem("dates"))
        : undefined;

      let localSpecialDay = localStorage.getItem("specialDay")
        ? JSON.parse(localStorage.getItem("specialDay"))
        : undefined;

      if (
        localPlanID &&
        localPlanName &&
        localArrangement &&
        localArrangement.name &&
        localBasePrice >= 0 &&
        localTypeAdditional >= 0 &&
        localQuantityAdditional >= 0 &&
        localQuantity >= 0 &&
        dates &&
        dates.length >= 0 &&
        localSpecialDay &&
        localSpecialDay.length >= 0
      ) {
        setPlanID(localPlanID);
        setPlanName(localPlanName);
        setArrangement(localArrangement.name);
        setBasePrice(localBasePrice);
        setAdditionalPrice(localTypeAdditional + localQuantityAdditional);
        setQuantity(localQuantity);
        setTotalPrice(
          localBasePrice + localTypeAdditional + localQuantityAdditional
        );
        setTextDates(
          dates.map(
            (date) =>
              date[8] +
              date[9] +
              "/" +
              date[5] +
              date[6] +
              "/" +
              date[0] +
              date[1] +
              date[2] +
              date[3]
          )
        );
        setSpecialDay(localSpecialDay);
        dates = dates.map((date) => {
          if (date && date.length === 10) {
            return (
              <div>
                {date[8] +
                  date[9] +
                  "/" +
                  date[5] +
                  date[6] +
                  "/" +
                  date[0] +
                  date[1] +
                  date[2] +
                  date[3]}
              </div>
            );
          } else {
            history.push(`/`);
          }

          return;
        });

        setDates(dates);
      } else {
        history.push(`/`);
      }

      getPlanImage();
    }

    async function getPlanImage() {
      try {
        let _id = localStorage.getItem("plan_id");

        if (_id) {
          let result = await sendRequest.getCheckout({ id: _id });

          if (result && result.planImage) {
            setPlanImage(result.planImage);
          } else {
            history.push(`/`);
          }
        }
      } catch (e) {
        throw e;
      }
    }

    getLocalStorageData();
  }, []);

  // url plan_id, para verificar quando houver mudança na url
  const { planId } = useParams();

  useEffect(() => {
    async function validatePlan() {
      let localPlanID = localStorage.getItem("plan_id")
        ? localStorage.getItem("plan_id")
        : null;

      let localArrangement = localStorage.getItem("selectedArrangementType")
        ? JSON.parse(localStorage.getItem("selectedArrangementType")).id
        : null;
      let localQuantity = localStorage.getItem("flowerArrangements")
        ? localStorage.getItem("flowerArrangements")
        : null;

      let localPrice = localStorage.getItem("price")
        ? JSON.parse(localStorage.getItem("price"))
        : null;

      let localTotalValue =
        localPrice.plan +
        localPrice.flowerArrangements +
        localPrice.arrangementType;
      //console.log(localPlanID, localArrangement, Number(localQuantity.replaceAll(`"`, "")), localTotalValue);

      if (
        planId &&
        localPlanID &&
        localArrangement &&
        localQuantity &&
        localPrice
      ) {
        // TODO adicionar campo do identifier no One
        const resultLocalPlanId = await sendRequest.validatePlan({
          planID: localPlanID,
          planIdentifier: planId,
          arrangementID: localArrangement,
          quantity: Number(localQuantity.replaceAll(`"`, "")),
          totalValue: localTotalValue,
        });

        if (resultLocalPlanId === true) {
          //alert("requisição para verificação com dados do plano vindos do localStorage retornou true")
        } else {
          //alert("requisição para verificação com dados do plano vindos do localStorage retornou false")
        }
      } else {
        history.push("/");
      }
    }

    async function getCheckout() {
      const plan_id = localStorage.getItem("plan_id");
      const result = await sendRequest.getCheckout({ id: plan_id });

      if (
        result &&
        result.purchaseCompletionMessage &&
        result.planName &&
        result.planImage
        // result.useTerms
      ) {
        setPlanImage(result.planImage);
      }
    }

    window.addEventListener("storage", (e) => {
      history.push(`/`);
    });
    validatePlan();
    getCheckout();
  }, []);

  return (
    <ChakraProvider>
      <Navbar />
      <Container>
        {!loadingCheckout ? (
          <Table
            isFitted
            index={tabIndex}
            onChange={setTabIndex}
            orientation={Mobile() ? "vertical" : "horizontal"}
          >
            <TabList>
              <Tab
                name="Dados Pessoais"
                disabled={tabIndex === 3 || shopping}
              />
              <Tab
                name="Endereço"
                disabled={!keepGoingPD || tabIndex === 3 || shopping}
              />
              <Tab
                name="Pagamento"
                disabled={
                  !keepGoingPD || !keepGoingE || tabIndex === 3 || shopping
                }
              />
              <Tab
                name="Compra Finalizada"
                disabled={!keepGoingPD || !keepGoingE || !keepGoingP}
              />
            </TabList>
            <TabPanels>
              <TabPanel>
                <PersonalData
                  setKeepGoingPD={setKeepGoingPD}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>
              <TabPanel>
                <Address
                  setKeepGoingE={setKeepGoingE}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>
              <TabPanel>
                <Payment
                  setKeepGoingP={setKeepGoingP}
                  setTabIndex={setTabIndex}
                  setShopping={setShopping}
                />
              </TabPanel>
              <TabPanel>
                <CompletedPurchase />
              </TabPanel>
            </TabPanels>
          </Table>
        ) : (
          <h1>Carregando...</h1>
        )}
      </Container>
      <Footer />
    </ChakraProvider>
  );
}

export default Checkout;

