//React =============================================================================
import React, { useContext, useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
//Style =============================================================================
import { Container, Paper, InputsContainer } from "./style";
import { Mobile } from "../../../../Hooks/Responsive";
//Components ========================================================================
import Input from "../../Components/Inputbox";
import Checkbox from "../../Components/Checkbox";
import Button from "../../Components/Button";
import Card from "../../Components/Card";
//Context ===========================================================================
import { CheckoutContext } from "../../../../Contexts/CheckoutContext";
//===================================================================================
import sendRequest from "../../../../Services/sendRequest";

function PersonalData(props) {
  //Context Data ====================================================================
  const {
    name,
    setName,
    email,
    setEmail,
    emailConfirmation,
    setEmailConfirmation,
    cpf,
    setCPF,
    phone,
    setPhone,
    deceased,
    setDeceased,
    holder,
    setHolder,
  } = useContext(CheckoutContext);
  //=================================================================================

  //Personal Data Error =============================================================
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailConfirmationError, setEmailConfirmationError] = useState(false);
  const [cpfError, setCPFError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [deceasedError, setDeceasedError] = useState(false);
  const [holderError, setHolderError] = useState(false);
  //Liberar Verificação de Erros ====================================================
  const [check, setCheck] = useState(false);
  //Política de Privacidade =========================================================
  const [policy, setPolicy] = useState(false);
  //=================================================================================

  const toast = useToast();

  function next() {
    if (
      name &&
      !nameError &&
      email &&
      !emailError &&
      emailConfirmation &&
      !emailConfirmationError &&
      cpf &&
      !cpfError &&
      phone &&
      !phoneError &&
      deceased &&
      !deceasedError &&
      ((holder && !holderError) || !holder)
    ) {
      if (policy) {
        props.setKeepGoingPD(true);
        props.setTabIndex(1);

        toast({
          title: "Dados Pessoais Preenchidos",
          description: "Agora preencha o seu Endereço 🤩",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Política de Privacidade",
          description: "Lembre-se de aceitar a Política de Privacidade 🧐",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      props.setKeepGoingPD(false);
    }
  }

  return (
    <Container>
      <Paper>
        <Input
          title="Nome Completo *"
          placeholder="Digite o Seu Nome"
          half={false}
          onChange={setName}
          value={name}
          lastNameValidation={true}
          check={check}
          setError={setNameError}
        />

        <Input
          title="E-mail *"
          placeholder="Digite o Seu E-mail"
          half={false}
          emailValidation={true}
          onChange={setEmail}
          value={email}
          check={check}
          setError={setEmailError}
        />

        <Input
          title="Confirmação de E-mail *"
          placeholder="Confirme o Seu E-mail"
          half={false}
          onChange={setEmailConfirmation}
          value={emailConfirmation}
          equalValidation={email}
          check={check}
          setError={setEmailConfirmationError}
        />

        <InputsContainer>
          <Input
            title="CPF *"
            placeholder="Digite o Seu CPF"
            half={true}
            cpfValidation={true}
            onChange={setCPF}
            value={cpf}
            check={check}
            setError={setCPFError}
            number={true}
          />

          <Input
            title={Mobile() ? "Tel. / Cel. *" : "Telefone / Celular *"}
            placeholder="Digite o Seu Telefone ou Celular"
            half={true}
            phoneValidation={true}
            onChange={setPhone}
            value={phone}
            check={check}
            setError={setPhoneError}
          />
        </InputsContainer>

        <Input
          title={Mobile() ? "Nome do Falecido" : "Nome Completo do Falecido  *"}
          placeholder="Digite o Nome Completo do Falecido"
          half={false}
          onChange={setDeceased}
          value={deceased}
          lastNameValidation={true}
          check={check}
          setError={setDeceasedError}
        />

        <Input
          title="Nome Completo do Titular"
          placeholder="Digite o Nome Completo do Titular"
          half={false}
          onChange={setHolder}
          value={holder}

          /* lastNameValidation={true}
        check={check}
        setError={setHolderError} */
        />

        <Checkbox half={false} onChange={setPolicy} value={policy} />

        <Button
          text="Próximo Passo"
          half={false}
          onClick={() => {
            setCheck(true);
            next();
          }}
        />
      </Paper>

      <Card />
    </Container>
  );
}

export default PersonalData;

