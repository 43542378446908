import moment from 'moment';

export function validateHolidayDate(newDate, dates) {
  if(dates.every(date => {return date !== 'yyyy-mm-dd'})) {
    return { error: "Você já escolheu todas as datas 📅" };
  };


  const newDateMoment = new Date(newDate);
  newDateMoment.setFullYear(new Date().getFullYear()); // TODO pegar o ano atual dinamicamente
  const now = moment();

  // Verifica se data Dia das Maes ou dos Pais ja foi inserida manualmente e
  if (dates.some((date) => date === new Date().getFullYear().toString() + "-" + newDate) ||
      dates.some((date) => date === (new Date().getFullYear()+1).toString() + "-" + newDate)) {
    return { error: "O feriado coincide com uma data já inserida, caso deseje que ambas datas sejam incluídas, favor entre em contato no momento da renovação 📅" };
  } 
  // 360 horas = 15 dias
  if (now.diff(newDateMoment, "hours") >= -360 && new Date().valueOf() <= newDateMoment.valueOf()){
    return { error: "O feriado deve ter pelo menos 15 dias completos de diferença a partir de hoje 📅"};
  }

 
  if (new Date().valueOf() >= newDateMoment.valueOf()){ 
    return { year: new Date().getFullYear() + 1};
  } else {
    return { year: new Date().getFullYear() };
  }
}

export function validateInputDate(insertedDate, dates, holidaysDates) {
  if(dates.every(date => {return date !== 'yyyy-mm-dd'})) {
    return { error: "Já escolheu todas as datas 📅" };
  };

  if (dates.some((date) => date === insertedDate)) {
    return { error: "Data já inserida 📅" };
  };

  const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[1-9]|2[1-9])$/;
  if(!insertedDate || insertedDate.length !== 10 || insertedDate === "" || dateRegex.test(insertedDate)) {
    return { error: "Favor inserir uma data válida 📅" }
  }


  
  const insertedDateMoment = moment(insertedDate);
  const now = moment();
  const nextYear = moment().add(1, 'Y');
  
  if(now.diff(insertedDateMoment, 'years') !== 0 || new Date().valueOf() > insertedDateMoment.valueOf()){
    return { error: "Insira datas em até 1 ano a partir de data de hoje 📅"};
  }
  if(now.diff(insertedDateMoment, 'hours') >= -96) {
    return { error: "A data inserida deve ter pelo menos 4 dias completos de diferença a partir de hoje 📅" };
  }
  
  const holidaysDatesMoment = holidaysDates.map(holiday => {
    let holidayMoment = moment(moment(now.format('YYYY') + "-" + holiday.date, "YYYY-MM-DD"))
    if(new Date().valueOf() < holidayMoment.valueOf()){
      return holidayMoment;
    }
    else {
      return moment(moment(nextYear.format('YYYY') + "-" + holiday.date, "YYYY-MM-DD"))
    }
  });

  let isHoliday = null;
  let error = {error: null};
  holidaysDatesMoment.forEach(h => {
    let holiday = h.format("DD/MM/YYYY");
    let inserted = insertedDateMoment.format("DD/MM/YYYY");

    const one = h.add(1, 'days').format("DD/MM/YYYY");
    const two = h.add(1, 'days').format("DD/MM/YYYY");
    const three = h.add(1, 'days').format("DD/MM/YYYY");
    const four = h.add(1, 'days').format("DD/MM/YYYY");

    //Se a data escolhida é um feriado
    if(holiday === inserted) {
      isHoliday = h;
    } else if(
      one === inserted ||
      two === inserted ||
      three === inserted ||
      four === inserted
    ) {
      error.error = "A data escolhida é subsequente a um feriado, portanto, não pode ser escolhida 📅";
    }
  });


  if(error.error !== null) {
    return error;
  }
  
  if(isHoliday && !error.error){
    //console.log(isHoliday.format("DD-MM-YYYY"));
    //console.log(now.format("DD-MM-YYYY"));
    //verificar se o feriado esta sendo marcado com 15 dias de antecedência
    if(now.format("DD-MM-YYYY") <= isHoliday.subtract(15, 'days').format("DD-MM-YYYY")){
      //liberado
      return { date: isHoliday._i };
    } else {
      //não-pode marcar
      return { error: "Feriado não está sendo marcado com 15 dias de antecedência 📅" }
    }
  }






  // holidaysDatesMoment.forEach(hit => {
  //   //console.log(hit);
  //   //console.log(hit.to(newDateMoment, 'hours'));
  //   if(hit.diff(newDateMoment, 'hours') <= -96 && hit.diff(newDateMoment, 'hours') >= 96) {
  //     //console.log("A data inserida está a menos de 4 dias de um feriado");
  //   }
  // });


  return { date: insertedDate };
}
