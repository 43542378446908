import sendRequest from "../../../../../Services/sendRequest";
import sendRequestGatewayPayment from "../../../../../Services/sendRequestGatewayPayment";
import { makePayloadCartaoAdyen } from "./factoryPayload";
import { Purchase } from "./purchase";

export class CreditCardPurchase extends Purchase {
  contractId = "";
  invoiceId = "";
  constructor(requisitionData, creditCardInfo, filial, totalPrice, name) {
    super(requisitionData);
    this.creditCardInfo = creditCardInfo;
    this.filial = filial;
    this.totalPrice = totalPrice;
    this.name = name;
    this.possuiErroSydle = false;
    this.possuiErroAdyen = false;
  }
  async execute() {
    await this.createSydleContract();
    if (this.possuiErroSydle) {
      return;
    }
    await this.createAdyenPayment();
    if (this.possuiErroAdyen) {
      return;
    }
    await this.registerCreditCardPayment();
    if (this.possuiErroSydle) {
      return;
    }
  }
  async createSydleContract() {
    try {
      const { data: responseCreateSydleContract } = await sendRequest.purchase(
        this.requisitionData
      );

      this.contractId = responseCreateSydleContract.contractId;
      this.invoiceId = responseCreateSydleContract.invoiceId;
    } catch (error) {
      this.possuiErroSydle = error;
      return;
    }
  }
  async createAdyenPayment() {
    try {
      const payloadPaymentAdyenCreditCard = makePayloadCartaoAdyen(
        this.invoiceId,
        this.contractId,
        this.creditCardInfo,
        this.totalPrice,
        this.name
      );

      await sendRequestGatewayPayment.postCheckoutCard(
        payloadPaymentAdyenCreditCard,
        this.filial
      );
    } catch (error) {
      this.possuiErroAdyen = error;
      return;
    }
  }
  async registerCreditCardPayment() {
    try {
      await sendRequest.registerPayment({ invoiceId: this.invoiceId });
    } catch (error) {
      this.possuiErroSydle = error;
      return;
    }
  }
}

