//React ==============================================================================
import React, { useState, useContext, useEffect, useRef } from "react";
import { useToast } from "@chakra-ui/react";
import Lottie from "react-lottie";

//Style ==============================================================================
import {
  Container,
  Paper,
  InputsContainer,
  CreditCardContainer,
  BiletOrCardContainer,
  BiletOrCardButton,
} from "./style";
import AdyenCheckout from "@adyen/adyen-web";
import Processing from "../../../../Assets/Checkout/Processing.json";
//Components =========================================================================
import Input from "../../Components/Inputbox";
import Checkbox from "../../Components/Checkbox";
import Combobox from "../../Components/Combobox";
import Button from "../../Components/Button";
import Card from "../../Components/Card";
import CreditCard from "../../Components/CreditCard";
import ValidityBox from "../../Components/ValidityBox";
//Context ===========================================================================
import { CheckoutContext } from "../../../../Contexts/CheckoutContext";
import { adyenStyles } from "./adyenConfig";
import "@adyen/adyen-web/dist/adyen.css";
import "../03Payment/adyen.custom.css";
import { makePayloadCartaoAdyen } from "./controller/factoryPayload";
import { CreditCardPurchase } from "./controller/creditCardPurchase";
import { BankSlipPurchase } from "./controller/bankSlipPurchase";
import { Billet } from "../04CompletedPurchase/style";
import { getValue } from "../../../../Utils/hash";
//===================================================================================

//Configuração do Ícone Animado
const defaultProcessing = {
  loop: true,
  autoplay: true,
  animationData: Processing,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Payment(props) {
  const toast = useToast();

  //Liberar Verificação de Erros ====================================================
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  //=================================================================================

  const [filial, setFilial] = useState(1);
  const paymentAdyenContainer = useRef(null);
  const [checkPayment, setCheckpayment] = useState(false);
  const [bankSlipRendered, setBankSlipRendered] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    cardValid: false,
    cardData: {},
  });
  const [cardDetails, setCardDetails] = useState();
  const [installmentsOptions, setInstallmentsOptions] = useState([
    { value: 1, text: "1x - Sem Juros" },
    { value: 2, text: "2x - Sem Juros" },
    { value: 3, text: "3x - Sem Juros" },
    { value: 4, text: "4x - Sem Juros" },
    { value: 5, text: "5x - Sem Juros" },
    { value: 6, text: "6x - Sem Juros" },
  ]);

  const {
    setInstallments,
    isBillet,
    setIsBillet,
    requisitionData,
    totalPrice,
    name,
    setBilletLink,
    setCheckoutSuccess,
  } = useContext(CheckoutContext);

  useEffect(() => {
    setIsBillet(null);
    let array = installmentsOptions;

    let localQuantity = localStorage.getItem("flowerArrangements")
      ? Number(localStorage.getItem("flowerArrangements").replaceAll(`"`, ""))
      : null;

    for (let i = 0; i < 6 - localQuantity; i++) {
      array.pop();
    }

    setInstallmentsOptions(array);
  }, []);

  async function purchase() {
    try {
      setLoading(true);
      props.setShopping(true);
      setCheck(true);
      if (!isBillet) {
        await purchaseCreditCard();
      }
      if (isBillet) {
        await purchaseBankSlip();
      }
    } catch (error) {
      setLoading(false);
      props.setShopping(false);
    } finally {
      setLoading(false);
    }
  }

  async function purchaseBankSlip() {
    if (!checkPayment) {
      return toast({
        title: "Atenção",
        description:
          "Lembre-se de preencher todos os dados corretamente e marcar a caixa de confirmação dos termos de uso.",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
    const bankSlipPurchaseResponse = new BankSlipPurchase(
      requisitionData,
      filial,
      totalPrice,
      name
    );
    await bankSlipPurchaseResponse.execute();

    if (bankSlipPurchaseResponse.possuiErroSydle) {
      props.setShopping(false);

      toast({
        title: "Infelizmente, tivemos um erro ao finalizar sua compra.",
        description: bankSlipPurchaseResponse.possuiErroSydle,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      await new Promise((r) => setTimeout(r, 1000));
      setIsBillet(true);
      return;
    }
    if (bankSlipPurchaseResponse.possuiErroAdyen) {
      props.setShopping(false);

      toast({
        title: "Infelizmente, tivemos um erro ao gerar o seu boleto.",
        description: "Por favor, tente novamente",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      await new Promise((r) => setTimeout(r, 1000));
      setIsBillet(true);
      return;
    }
    setBilletLink(bankSlipPurchaseResponse.renderedObject);
    successPurchase();
    setCheckoutSuccess(true);
    props.setKeepGoingP(true);
    props.setTabIndex(3);
  }
  async function purchaseCreditCard() {
    if (!cardInfo.cardValid || !checkPayment) {
      return toast({
        title: "Atenção",
        description:
          "Lembre-se de preencher todos os dados corretamente e marcar a caixa de confirmação dos termos de uso.",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
    const creditCardPurchaseResponse = new CreditCardPurchase(
      requisitionData,
      cardInfo,
      filial,
      totalPrice,
      name
    );
    await creditCardPurchaseResponse.execute();

    if (creditCardPurchaseResponse.possuiErroSydle) {
      props.setShopping(false);

      toast({
        title: "Infelizmente, tivemos um erro ao finalizar sua compra.",
        description: creditCardPurchaseResponse.possuiErroSydle,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      await new Promise((r) => setTimeout(r, 1000));
      setIsBillet(false);
      return;
    }
    if (creditCardPurchaseResponse.possuiErroAdyen) {
      props.setShopping(false);

      toast({
        title: "Infelizmente, tivemos um erro ao finalizar sua compra.",
        description:
          "Por favor, verifique os dados do seu cartão e se você possui limite disponível para a compra",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      await new Promise((r) => setTimeout(r, 1000));
      setIsBillet(false);
      return;
    }
    successPurchase();
    setCheckoutSuccess(true);
    props.setKeepGoingP(true);
    props.setTabIndex(3);
  }
  function successPurchase() {
    toast({
      title: "Meus parabéns! Você finalizou sua compra.",
      description:
        "Agora você faz parte do Clube das Flores. Aguarda a confirmação da chegada de suas flores pelo Whatsapp ou E-mail.",
      status: "success",
      duration: 9000,
      isClosable: true,
    });
  }
  const handleOnChange = (state, component) => {
    state.isValid;
    state.data;
    component;
    if (state.data?.installments) {
      setInstallments(state.data.installments.value);
    } else {
      setInstallments(1);
    }
    setCardInfo({
      cardValid: state.isValid,
      cardData: state.data,
    });
  };
  const handleOnAdditionalDetails = (state, component) => {
    state.data;
    component;
    setCardDetails({
      state,
      component,
    });
  };

  const brands = [
    "visa",
    "mc",
    "elo",
    "amex",
    "hiper",
    "hipercard"
  ];

  const paymentMethods = [{
    brands,
    "name": "Cartão de crédito",
    "type": "scheme"
  }]

  useEffect(() => {
    (async () => {
      try {
        if (!isBillet) {
          await new Promise((r) => setTimeout(r, 100));
          const configuration = {
            onPaymentCompleted: (result, component) => {
              console.info(result, component);
            },
            onError: (error, component) => {
              console.error(error.name, error.message, error.stack, component);
            },
            amount: {
              value: totalPrice * 100,
              currency: "BRL",
            },
            style: adyenStyles,
            paymentMethodsResponse: {
              paymentMethods,
            },
            locale: "pt-BR",
            environment: getValue(process.env.REACT_APP_7),
            clientKey: getValue(process.env.REACT_APP_6),
            analytics: {
              enabled: true,
            },
            paymentMethodsConfiguration: {
              card: {
                showPayButton: false,
                installmentOptions: {
                  card: {
                    values: [1, 2, 3],
                    plans: ["regular"],
                  },
                  showInstallmentAmounts: true,
                },
              },
            },
            onChange: handleOnChange,
            onAdditionalDetails: handleOnAdditionalDetails,
          };

          const checkout = await AdyenCheckout(configuration);

          checkout.create("card").mount(paymentAdyenContainer.current);
        }
      } catch (error) {
        setLoading(false);
        props.setShopping(false);
      }
    })();
  }, [isBillet]);

  return !loading ? (
    <Container>
      <Paper>
        <BiletOrCardContainer>
          <BiletOrCardButton
            left={true}
            isBillet={!isBillet}
            onClick={() => setIsBillet(false)}
          >
            Cartão de Crédito
          </BiletOrCardButton>
          <BiletOrCardButton
            isBillet={isBillet}
            onClick={() => setIsBillet(true)}
          >
            Boleto Bancário
          </BiletOrCardButton>
        </BiletOrCardContainer>

        {!isBillet ? (
          <>
            <InputsContainer>
              <pre
                style={{
                  position: "static",
                  width: "100%",
                  whiteSpace: "wrap",
                }}
                ref={paymentAdyenContainer}
              />
              <div>
                <Checkbox
                  half={false}
                  interspace={false}
                  value={checkPayment}
                  onChange={setCheckpayment}
                />
                <Button
                  text="Realizar Pagamento"
                  half={false}
                  onClick={purchase}
                />
              </div>
            </InputsContainer>
          </>
        ) : (
          <>
            <Checkbox
              half={false}
              interspace={false}
              value={checkPayment}
              onChange={setCheckpayment}
            />
            <Button text="Realizar Pagamento" half={false} onClick={purchase} />
          </>
        )}
      </Paper>

      <Card />
    </Container>
  ) : (
    <Container>
      <Paper>
        <div
          style={{
            fontSize: 20,
            fontWeight: 600,
            marginTop: 20,
            marginBottom: -50,
          }}
        >
          Processando os Dados...
        </div>

        <Lottie
          options={defaultProcessing}
          height={300}
          width={300}
          isClickToPauseDisabled
        />
      </Paper>

      <Card />
    </Container>
  );
}

export default Payment;

