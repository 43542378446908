import React from "react";

import { Container } from './style'

function BlueButton(props) {
  return (
    <Container margin={props.margin} onClick={props.onClick}>{props.children}</Container>
  )
}

export default BlueButton