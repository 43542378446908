import { useContext, useState } from "react";
import { PlanContext } from "../../../../Contexts/PlanContext";
import {
  BuyButton,
  Container,
  DatesContainer,
  PlanOptionContainer,
  Pricing,
  PricingDivider,
  Testimonial,
} from "./style";

import formatDate from "../../../../Utils/dateFormat";
import { useHistory } from "react-router-dom";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useToast } from "@chakra-ui/react";

import { BASE_URL_FLOWERS_CLUB } from "../../../../App";
import { formatacaoNumeroBrasil } from "../../../../Utils/priceFormat";

function RightSection() {
  const [isChecked, setIsChecked] = useState({
    terms: false,
    mail: false,
    termsLocal: false,
  });

  const {
    dates,
    selectedArrangementType,
    flowerArrangements,
    planName,
    planId,
    plan_id,
    testimonials,
    basePrice,
    arrangementTypePrice,
    arrangementQuantityPrice,
    setPrice,
  } = useContext(PlanContext);

  const history = useHistory();

  const toast = useToast();

  function handleBuyButton() {
    const selectedDates = dates.filter((date) => date !== "yyyy-mm-dd");

    if (selectedArrangementType.id === "") {
      toast({
        title: "Preencha todos os campos.",
        description:
          "É necessário escolher uma opção de arranjo para prosseguir para o checkout 💐",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (selectedDates.length !== Number(flowerArrangements)) {
      toast({
        title: "Preencha todos os campos.",
        description:
          "É necessário escolher todas as datas de entrega para prosseguir para o checkout 📅",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (!isChecked.terms) {
      toast({
        title: "Política de Privacidade",
        description: "Lembre-se de aceitar a Política de Privacidade 🧐",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (!isChecked.termsLocal) {
      toast({
        title: "Campos não selecionados",
        description:
          "Lembre-se de marcar que está ciente sobre os locais atendidos 🧐",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    localStorage.setItem("plan_id", plan_id);

    setPrice({
      plan: basePrice,
      arrangementType: arrangementTypePrice,
      flowerArrangements: arrangementQuantityPrice,
    });

    history.push(`${BASE_URL_FLOWERS_CLUB}checkout/${plan_id && planId}/`);

    //return `/checkout/${plan_id && planId}`;
  }

  return (
    <Container>
      <Pricing>
        <b>
          <h2>Preço Base</h2>
        </b>
        <p style={{ marginTop: 10 }}>
          R$
          <b>
            {basePrice ? (
              ` ${basePrice}`.replace(".", ",")
            ) : (
              <Skeleton inline={true} width={"20%"} />
            )}
          </b>
        </p>

        <PricingDivider />

        <PlanOptionContainer>
          <b>Opção de Arranjo:</b>{" "}
          <div>
            <span>
              {selectedArrangementType.name !== ""
                ? selectedArrangementType.name
                : "Não escolhida"}
            </span>
            <span className="value">
              {selectedArrangementType.name !== ""
                ? `+ R$${formatacaoNumeroBrasil(arrangementTypePrice)}`
                : ""}
            </span>
          </div>
        </PlanOptionContainer>

        <PricingDivider />
        <PlanOptionContainer style={{ marginTop: "5px" }}>
          <b>Quantidade de Arranjos:</b>{" "}
          <div>
            <span>
              {flowerArrangements !== "" ? flowerArrangements : "Não escolhido"}
            </span>
            <span className="value">
              {flowerArrangements !== "" && arrangementQuantityPrice !== null
                ? `+ R$${formatacaoNumeroBrasil(arrangementQuantityPrice)}`
                : ""}
            </span>
          </div>
        </PlanOptionContainer>
        <PricingDivider />
        {dates && dates.length > 0 && dates[0] !== "yyyy-mm-dd" ? (
          <>
            <DatesContainer>
              <b>Datas Selecionadas:</b>{" "}
              {dates &&
                dates.map((date, idx) => {
                  return (
                    <p key={idx}>{date !== "yyyy-mm-dd" && formatDate(date)}</p>
                  );
                })}
            </DatesContainer>

            <PricingDivider />
          </>
        ) : null}

        <b>
          <h2>Valor Total</h2>
        </b>
        <p style={{ marginTop: 10 }}>
          R$
          <b>
            {basePrice ? (
              ` ${formatacaoNumeroBrasil(
                basePrice + arrangementTypePrice + arrangementQuantityPrice
              )}`
            ) : (
              <Skeleton inline={true} width={"20%"} />
            )}
          </b>
        </p>

        <PricingDivider />

        <p>No cartão ou no boleto.</p>

        <PricingDivider />

        <div className="checkbox">
          <input
            type="checkbox"
            id="terms"
            checked={isChecked.terms}
            onChange={(e) =>
              setIsChecked({ ...isChecked, [e.target.id]: !isChecked.terms })
            }
          />
          <label htmlFor="terms">Aceito a Política de Privacidade</label>
        </div>

        <div className="checkbox" style={{ marginTop: "10px" }}>
          <input
            type="checkbox"
            id="termsLocal"
            checked={isChecked.termsLocal}
            onChange={(e) =>
              setIsChecked({
                ...isChecked,
                [e.target.id]: !isChecked.termsLocal,
              })
            }
          />
          <label htmlFor="termsLocal">
            Estou ciente que somente o Cemitério Colina Belo Horizonte é
            atendido.
          </label>
        </div>

        <div className="checkbox" style={{ marginTop: "10px" }}>
          <input
            type="checkbox"
            id="mail"
            checked={isChecked.mail}
            onChange={(e) =>
              setIsChecked({ ...isChecked, [e.target.id]: !isChecked.mail })
            }
          />
          <label htmlFor="mail">
            Aceito receber emails sobre promoções do Grupo Zelo
          </label>
        </div>

        <BuyButton onClick={handleBuyButton}>Comprar agora</BuyButton>
      </Pricing>

      {/* <Testimonial>
        <CarouselContainer testimonials={testimonials} />
      </Testimonial> */}
    </Container>
  );
}

export default RightSection;

