import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-top: 35px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;

  &::after {
    position: absolute;
    width: 100%;
    bottom: -5px;
    content: "";
    height: 1px;
    background-color: var(--hydrangea-blue);
  }
`;
