import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--very-light-blue);
  font-family: "Montserrat", sans-serif;

  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;

  padding: 35px 0px;

  @media (max-width: 450px) {
    padding: 20px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: var(--very-light-blue);
  border-top: 1px solid #391761;
  font-family: "Montserrat", sans-serif;

  font-size: 24px;
  font-weight: 600;
  color: black;

  padding-top: 50px;
  padding-left: 22%;

  width: 100%;
  height: 100%;

  @media (max-width: 1440px) {
    padding-left: 18%;
  }

  @media (max-width: 1024px) {
    padding-left: 5%;
  }
`;

export const TitleLine = styled.div`
  border-bottom: 5px solid var(--hydrangea-blue);
  height: 10px;
  width: 50px;
  font-family: "Montserrat", sans-serif;
`;

