import React, { useContext } from "react";
import { Container, Paper, Title, SubTitle, Billet } from "./style";
import Lottie from "react-lottie";

import Card from "../../Components/Card";
import Preparing from "../../../../Assets/Checkout/Preparing.json";
import Download from "../../../../Assets/Checkout/Download.json";
import Home from "../../../../Assets/Checkout/Home.json";

//Context ===========================================================================
import { CheckoutContext } from "../../../../Contexts/CheckoutContext";
//===================================================================================

//Configuração do Ícone Animado
const defaultPreparing = {
  loop: true,
  autoplay: true,
  animationData: Preparing,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultLoading = {
  loop: true,
  autoplay: true,
  animationData: Download,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultHome = {
  loop: true,
  autoplay: true,
  animationData: Home,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function Payment() {
  const { billetLink, name, cpf, checkoutSuccess } =
    useContext(CheckoutContext);
  if (checkoutSuccess) {
    localStorage.clear();
  }
  return (
    <Container>
      <Paper>
        <Title>Compra Finalizada com Sucesso!</Title>
        <SubTitle>
          Agora você e sua família podem ficar mais tranquilos e viver bem!
          <br />
          Agradecemos pela confiança!
        </SubTitle>
        <Lottie
          options={defaultPreparing}
          height={300}
          width={300}
          isClickToPauseDisabled
        />

        {billetLink ? (
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Billet
              href={billetLink}
              download={`${cpf}-${name}-boleto-clube-das-flores`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>Baixar Boleto</div>
              <Lottie
                options={defaultLoading}
                height={50}
                width={50}
                isClickToPauseDisabled
              />
            </Billet>
          </div>
        ) : null}
      </Paper>

      <Card />
    </Container>
  );
}

export default Payment;

