//React =============================================================================
import React, { useContext, useEffect } from "react";
//Style =============================================================================
import {
  Card,
  Image,
  Title,
  SubTitle,
  Partial,
  TotalBox,
  Line,
  PaymentForm,
  Up,
  SubLine,
  TotalValue,
  TotalTitle,
  Info,
} from "./style";
//Context ===========================================================================
import { CheckoutContext } from "../../../../Contexts/CheckoutContext";
import { formatacaoNumeroBrasil } from "../../../../Utils/priceFormat";
//===================================================================================

function CardComponent() {
  //Context Data ====================================================================
  const {
    name,
    deceased,
    isBillet,
    keepGoingE,

    planName,
    arrangement,
    planImage,
    basePrice,
    additionalPrice,
    quantity,
    totalPrice,
    dates,
  } = useContext(CheckoutContext);

  return (
    <Card>
      <Title>{planName}</Title>
      <SubTitle>{arrangement}</SubTitle>
      {planImage ? <Image src={planImage} /> : <Image />}

      <Partial>
        R$ <Up>{formatacaoNumeroBrasil(basePrice)}</Up> /ano
      </Partial>
      <SubLine>
        + R$ {formatacaoNumeroBrasil(additionalPrice)} - {quantity} Arranjos por
        ano
      </SubLine>

      {dates && dates.length >= 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            textAlign: "right",
            marginTop: 20,
            width: "100%",
          }}
        >
          <div style={{ fontWeight: 800 }}>Datas da Entrega</div>
          {dates}
        </div>
      ) : null}

      <Line>
        <TotalTitle>Preço Total</TotalTitle>
        <TotalBox>
          <TotalValue>
            R$ <Up>{formatacaoNumeroBrasil(totalPrice)}</Up>
          </TotalValue>
        </TotalBox>
      </Line>

      <br />

      {name ? (
        <Info
          style={{
            marginTop: 20,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          <b>Contratante: </b> {name}
        </Info>
      ) : null}

      {deceased ? (
        <Info>
          <b>Falecido: </b> {deceased}
        </Info>
      ) : null}

      {keepGoingE ? (
        <Info>
          <b>Pagamento: </b>{" "}
          {isBillet ? "Boleto Bancário" : "Cartão de Crédito"}
        </Info>
      ) : null}

      <PaymentForm key={"pix"}>Cartão de Crédito, Boleto ou PIX</PaymentForm>
      <PaymentForm>Somente o Cemitério Colina BH é atendido</PaymentForm>
    </Card>
  );
}

export default CardComponent;

