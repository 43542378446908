import axios from "axios";
import { getValue } from "../Utils/hash";

export const sydleApi = axios.create({
  baseURL: `${getValue(process.env.REACT_APP_3)}${getValue(process.env.REACT_APP_4)}`,
  headers: {
    'Authorization': `Bearer ${getValue(process.env.REACT_APP_5)}`,
    'Content-Type': 'application/json',
  },
});

export const paymentApi = axios.create({
  baseURL: `${getValue(process.env.REACT_APP_8)}`,
  headers: {
    "x-auth-token": `${getValue(process.env.REACT_APP_9)}`,
    "Content-Type": "application/json",
  },
});