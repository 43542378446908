import React from "react";

import { Tab } from "@chakra-ui/react";

function TabC(props) {
  return (
    <Tab
      _selected={{ color: "white", bg: "#3867AB", borderRadius: 5}}
      isDisabled={props.disabled}
      fontWeight={600}
    >
      {props.name}
    </Tab>
  );
}

export default TabC;

