//Style Components ===========================================================
import React from "react";
import {
  Accordions,
  Container,
  Fotter,
  Header,
  Image,
  List,
  From,
  From2,
  FromLine,
  Price,
  SubTitle,
  Title,
  Button,
  Background,
  StyledAccordionButton,
  StyledPanel,
  StyledAccordionItem,
  LinkA,
} from "./style";
//============================================================================
import { Link } from "react-router-dom";
import { IsDesktopOrLaptopG } from "../../../../Hooks/Responsive";
import { BASE_URL_FLOWERS_CLUB } from "../../../../App";
//============================================================================
import priceFormat from "../../../../Utils/priceFormat";
import { Accordion, AccordionIcon, AccordionItem, Box } from "@chakra-ui/react";
//============================================================================

function DarkCard({ plan }) {
  return IsDesktopOrLaptopG() ? (
    <Background>
      <Container first={true}>
        <Header>
          <SubTitle>PLANO ANUAL</SubTitle>
          <Title>{plan && plan.name}</Title>
        </Header>
        <Image src={plan && plan.image} />
        <Accordions>
          {plan &&
            plan.planArrangementsTypes.map((hit, index) => {
              return (
                <>
                  <Accordion
                    defaultIndex={[0]}
                    style={{ width: "100%" }}
                    allowToggle
                    key={index}
                  >
                    <StyledAccordionItem>
                      <StyledAccordionButton>
                        <Box flex="1" textAlign="left">
                          {hit.title}
                        </Box>
                        <AccordionIcon />
                      </StyledAccordionButton>
                      <StyledPanel pb={4}>
                        <List>
                          {hit.items.map((item) => {
                            return <li>{item}</li>;
                          })}
                        </List>
                      </StyledPanel>
                    </StyledAccordionItem>
                  </Accordion>
                </>
              );
            })}
        </Accordions>
        <Fotter>
          <From>A PARTIR DE</From>
          <FromLine>
            <From2 style={{ marginRight: 5 }}>3x de R$ </From2>
            <Price>{plan && priceFormat.homeCardPrice(plan.basePrice)}</Price>
          </FromLine>
          <LinkA
            href={`${BASE_URL_FLOWERS_CLUB}${plan && plan.identifier}/`}
            style={{ textDecoration: "none" }}
          >
            <Button>ASSINE AGORA</Button>
          </LinkA>
        </Fotter>
      </Container>
    </Background>
  ) : (
    <Container first={true}>
      <Header>
        <SubTitle>PLANO ANUAL</SubTitle>
        <Title>{plan && plan.name}</Title>
      </Header>
      <Image src={plan && plan.image} />
      <Accordions>
        {plan &&
          plan.planArrangementsTypes.map((hit, index) => {
            return (
              <>
                <Accordion
                  defaultIndex={[0]}
                  style={{ width: "100%" }}
                  allowToggle
                >
                  <AccordionItem style={{ textAlign: "left" }}>
                    <StyledAccordionButton>
                      <Box flex="1" textAlign="left">
                        {hit.title}
                      </Box>
                      <AccordionIcon />
                    </StyledAccordionButton>
                    <StyledPanel pb={4}>
                      <List>
                        {hit.items.map((item) => {
                          return <li>{item}</li>;
                        })}
                      </List>
                    </StyledPanel>
                  </AccordionItem>
                </Accordion>
              </>
            );
          })}
      </Accordions>
      <Fotter>
        <From>A PARTIR DE</From>
        <FromLine>
          <From2 style={{ marginRight: 5 }}>3x de R$ </From2>
          <Price>{plan && priceFormat.homeCardPrice(plan.basePrice)}</Price>
        </FromLine>
        <LinkA
          href={`${BASE_URL_FLOWERS_CLUB}${plan && plan.identifier}/`}
          style={{ textDecoration: "none" }}
        >
          <Button>ASSINE AGORA</Button>
        </LinkA>
      </Fotter>
    </Container>
  );
}

export default DarkCard;

