import styled from "styled-components";

export const CarouselContainer = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 500px;
  font-family: 'Montserrat', sans-serif;

`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #ECEDF8;
  height: 100%;
  font-family: 'Montserrat', sans-serif;

`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #333;

  width: 30%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;

`;

export const Banner = styled.div`
  display: flex;
  justify-content: space-between;

  background-color: blue;

  width: 100%;
  height: 450px;
  font-family: 'Montserrat', sans-serif;

`;

export const StepByStepContainer = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(267.07deg, #0C3167 0%, #3867AB 100%);
  padding-top: 70px;

  width: 100%;
  height: 250px;
  font-family: 'Montserrat', sans-serif;

`;


export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100px;
  font-family: 'Montserrat', sans-serif;

`;

export const StepText = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;

  color: #f4c27a;

  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-style:normal;
  font-family: 'Montserrat', sans-serif;
`;

export const StepDescription = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;

  padding-left: 20px;
  padding-right: 20px;

  color: white;

  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  font-style:normal;
  font-family: 'Montserrat', sans-serif;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-top:1px solid #391761;
  background: linear-gradient(267.07deg, #0C3167 0%, #3867AB 100%);

  font-size: 24px;
  font-weight: 600;
  color: var(--ghostwhite);

  padding-top: 50px;
  padding-left: 5%;

  width: 100%;
  height: 100%;
  font-family: 'Montserrat', sans-serif;

`;

export const TitleLine = styled.div`
  border-bottom: 5px solid var(--light-blue);
  height: 10px;
  width:50px ;
  font-family: 'Montserrat', sans-serif;

`;

export const EllipseWithLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => props.first ? 47 : 0}%;
  width: 100%;
  font-family: 'Montserrat', sans-serif;

`;

export const Ellipse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 49px;
  height: 49px;

  font-weight: 600;
  color: #9B89B3;

  background: #FDF7FF;
  border: 3px solid ${props => props.borderColor ? props.borderColor : "#8992B3"};
  box-sizing: border-box;
  border-radius: 50%;
  font-family: 'Montserrat', sans-serif;

`;

export const Line = styled.div`
  width: 47%;
  height: 0px;
  border: 2px solid ${props => props.color ? props.color : "#C5C5C5"} ;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-family: 'Montserrat', sans-serif;

`;
