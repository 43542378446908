import styled from 'styled-components';


export const Content = styled.div`
  padding: 40px var(--laptop-large-side-margin) 100px;
  background-color: var(--very-light-blue);
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 60% 40%;
  color: var(--hydrangea-blue);

  @media(max-width: 1500px) {
    grid-template-columns: 1fr;
  }

  @media(max-width: 1024px) {
    padding: 40px 100px 100px;
  }

  @media(max-width: 768px) {
    padding: 40px var(--mobile-side-margin) 100px;
  }

  @media(max-width: 320px) {
    h1 {
      font-size: 18px;
    }
  }
`;