//React ======================================================================
import React, { useContext, useEffect } from "react";
//Style Components ===========================================================
import { Home } from "./style";
//Components =================================================================
import Carousel from "./Sections/Carousel";
import StepByStep from "./Sections/StepByStep";
import StepByStepMobile from "./Sections/StepByStepMobile";
import Plans from "./Sections/Plans";
import PlansMobile from "./Sections/PlansMobile";
import Doubts from "./Sections/Doubts";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
//Services ===================================================================
import sendRequest from "../../Services/sendRequest";
//Contexts ==================================================================
import { HomeContext } from "../../Contexts/HomeContext";

function Homepage() {
  const {
    setBanners,
    setHowItWorksSection,
    setPlansSection,
    setDoubtsSection,
  } = useContext(HomeContext);

  useEffect(() => {
    async function getHome() {
      const result = await sendRequest.getHome({});

      if (
        result &&
        result.banners &&
        result.howItWorksSection &&
        result.plansSection &&
        result.doubtsSection
      ) {
        setBanners(result.banners);
        setHowItWorksSection(result.howItWorksSection);
        setPlansSection(result.plansSection);
        setDoubtsSection(result.doubtsSection);
      }
    }

    // ? localStorage.setItem("dates", `["yyyy-mm-dd", "yyyy-mm-dd", "yyyy-mm-dd"]`);  //as datas já são resetadas ao entrar na página Plan, aqui também é necessário?

    getHome();
  }, [setBanners, setHowItWorksSection, setPlansSection, setDoubtsSection]);

  return (
    <Home>
      <Navbar />

      <Carousel />

      <StepByStep />
      <StepByStepMobile />

      <Plans />
      <PlansMobile />

      <Doubts />

      <Footer />
    </Home>
  );
}

export default Homepage;

