import styled from "styled-components";

export const StepByStepContainer = styled.div`
  /* This renders the buttons above... Edit me! */
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(267.07deg, #0C3167 0%, #3867AB 100%);

  width: 100%;
  height: 250px;

  font-family: 'Montserrat', sans-serif;

  padding-bottom: 50px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-top:1px solid #391761;
  background: linear-gradient(267.07deg, #0C3167 0%, #3867AB 100%);

  font-size: 24px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;

  color: var(--ghostwhite);

  padding-top: 50px;
  padding-left: 22%;

  width: 100%;
  height: 100%;

  @media(max-width: 2560px) {
    padding-left: var(--laptop-4k-side-margin);
    padding-right: var(--laptop-4k-side-margin);
  }

  @media(max-width: 1440px) {
    padding-left: var(--laptop-g-side-margin);
    padding-right: var(--laptop-g-side-margin);
  }

  @media(max-width: 1024px) {
    padding-left: var(--laptop-p-side-margin);
    padding-right: var(--laptop-p-side-margin);
  }

  @media(max-width: 768px) {
    padding-left: var(--tablet-side-margin);
    padding-right: var(--tablet-side-margin);
  }

  @media(max-width: 425px) {
    padding-left: var(--movel-g-side-margin);
    padding-right: var(--movel-g-side-margin);
  }
`;

export const TitleLine = styled.div`
  border-bottom: 5px solid var(--light-blue);
  height: 10px;
  width:50px ;
  font-family: 'Montserrat', sans-serif;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  width: 250px;
  height: 100px;
  font-family: 'Montserrat', sans-serif;
`;

export const EllipseWithLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 45%;
  font-family: 'Montserrat', sans-serif;
`;

export const Ellipse = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 49px;
  height: 49px;

  font-weight: 600;
  color: #9B89B3;

  font-family: 'Montserrat', sans-serif;


  background: #FDF7FF;
  border: 3px solid ${props => props.borderColor ? props.borderColor : "#8992B3"};
  box-sizing: border-box;
  border-radius: 50%;
`;

export const Line = styled.div`
  width: 110px;
  height: 0px;
  border: 2px solid ${props => props.color ? props.color : "#C5C5C5"} ;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-family: 'Montserrat', sans-serif;

`;

export const StepText = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;

  color: #f4c27a;

  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-style:normal;
  font-family: 'Montserrat', sans-serif;
`;

export const StepDescription = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;

  color: white;

  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  font-style:normal;
  font-family: 'Montserrat', sans-serif;
`;
