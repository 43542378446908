import React, { useContext } from "react";
import { HomeContext } from "../../../../Contexts/HomeContext";
//Style Components ===========================================================
import {
  Container,
  Accordions,
  Title,
  TitleLine,
  SkeletonContainer,
  StyledPanel,
  StyledAccordionButton,
} from "./style";
//============================================================================
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Accordion, AccordionIcon, AccordionItem, Box } from "@chakra-ui/react";

function Doubts() {
  const { doubtsSection } = useContext(HomeContext);

  return (
    <>
      <Title id="duvidas">
        {doubtsSection ? doubtsSection.title : "Dúvidas"}
        <TitleLine />
      </Title>
      <Container>
        <Accordions>
          {doubtsSection ? (
            doubtsSection.doubts.map((doubt, index) => {
              return (
                <Accordion style={{ width: "100%" }} allowToggle>
                  <AccordionItem>
                    <StyledAccordionButton>
                      <Box flex="1" textAlign="left">
                        {doubt.question}
                      </Box>
                      <AccordionIcon />
                    </StyledAccordionButton>
                    <StyledPanel pb={4}>{doubt.answer}</StyledPanel>
                  </AccordionItem>
                </Accordion>
              );
            })
          ) : (
            <SkeletonContainer>
              <Skeleton
                count={6}
                wrapper={SkeletonContainer}
                baseColor="#d8daf7"
              />
            </SkeletonContainer>
          )}
        </Accordions>
      </Container>
    </>
  );
}

export default Doubts;

