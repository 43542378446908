const priceFormat = {
  homeCardPrice: (fullBasePrice) => {
    const valorEm3Vezes = Number(Math.round(fullBasePrice * 100) / 100) / 3;
    return valorEm3Vezes.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
};

export const formatacaoNumeroBrasil = (numero) => {
  const numeroFormatado = Math.round(Number(numero) * 100) / 100;

  if (!isNaN(numeroFormatado)) {
    const numeroFormatadoString = numeroFormatado.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return numeroFormatadoString;
  } else {
    return null;
  }
};

export default priceFormat;

