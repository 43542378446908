import styled from 'styled-components';

export const Container = styled.button`
  padding: 5px 10px;
  width: fit-content;
  color: #fff;
  background-color: var(--hydrangea-blue);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin: ${props => props.margin};
  font-family: 'Montserrat', sans-serif;

`;
