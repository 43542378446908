//React =============================================================================
import React, { createContext, useEffect, useState } from "react";
//===================================================================================

export const CheckoutContext = createContext();

export function CheckoutProvider({ children }) {
  //Page Data =====================================================================
  const [planID, setPlanID] = useState("");

  const [planName, setPlanName] = useState("");
  const [arrangement, setArrangement] = useState("");
  const [planImage, setPlanImage] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [additionalPrice, setAdditionalPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [totalPrice, setTotalPrice] = useState("");

  const [specialDay, setSpecialDay] = useState([]);
  const [textDates, setTextDates] = useState([]);
  const [dates, setDates] = useState([]);
  //===============================================================================

  //Personal Data =================================================================
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirmation, setEmailConfirmation] = useState("");
  const [cpf, setCPF] = useState("");
  const [phone, setPhone] = useState("");
  const [deceased, setDeceased] = useState("");
  const [holder, setHolder] = useState("");
  //===============================================================================

  //Address Data ==================================================================
  const [zipcode, setZipcode] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  //===============================================================================

  //Payment Data ==================================================================
  const [cardNumber, setCardNumber] = useState("");
  const [creditCardType, setCreditCardType] = useState("");
  const [cardholderName, setCardholderName] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cvc, setCvc] = useState("");
  const [cvcDigits, setCvcDigits] = useState(3);
  const [installments, setInstallments] = useState(0);
  const [isBillet, setIsBillet] = useState(false);
  const [billetLink, setBilletLink] = useState("");
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  //===============================================================================

  //Requisiton Data ===============================================================
  const [requisitionData, setRequisitionData] = useState("");
  const [requisitionResult, setRequisitionResult] = useState({});

  useEffect(() => {
    setRequisitionData(
      JSON.stringify(
        {
          purchase: {
            planId: planID,
            summary: {
              arrangement: arrangement,
              quantity: quantity,
              totalPurchaseAmount: totalPrice,
              installments: Number(installments),
              inputData: {
                deceasedName: deceased,
                dates: textDates,
                specialDay: specialDay,
              },
            },
            state: {
              stateID: "590b7f44f5ca5307c8e841cd",
              region: "null",
            },
            customer: {
              name: name,
              email: email,
              phone: phone,
              telephone: phone,
              cpf: cpf,
              address: {
                zipcode: zipcode,
                street: street,
                number: number,
                quarter: neighborhood,
                city: city,
                state: state,
                country: "Brasil",
                complement: complement,
              },
            },
            paymentData: {
              paymentMethod: {
                bankBillet: isBillet,
                creditCard: !isBillet,
              },
            },
          },
        },
        null,
        2
      )
    );
  }, [
    name,
    email,
    cpf,
    phone,
    deceased,
    holder,
    zipcode,
    street,
    city,
    state,
    number,
    neighborhood,
    complement,
    cardNumber,
    cardholderName,
    month,
    year,
    cvc,
    installments,
    creditCardType,
    isBillet,
    requisitionResult,
  ]);
  //===============================================================================

  return (
    <CheckoutContext.Provider
      value={{
        name,
        setName,
        email,
        setEmail,
        emailConfirmation,
        setEmailConfirmation,
        cpf,
        setCPF,
        phone,
        setPhone,
        deceased,
        setDeceased,
        holder,
        setHolder,

        zipcode,
        setZipcode,
        street,
        setStreet,
        city,
        setCity,
        state,
        setState,
        number,
        setNumber,
        neighborhood,
        setNeighborhood,
        complement,
        setComplement,
        cardNumber,
        setCardNumber,
        creditCardType,
        setCreditCardType,
        cardholderName,
        setCardholderName,
        month,
        setMonth,
        year,
        setYear,
        cvc,
        setCvc,
        cvcDigits,
        setCvcDigits,
        installments,
        setInstallments,
        isBillet,
        setIsBillet,
        billetLink,
        setBilletLink,
        planID,
        setPlanID,
        planName,
        setPlanName,
        arrangement,
        setArrangement,
        planImage,
        setPlanImage,
        basePrice,
        setBasePrice,
        additionalPrice,
        setAdditionalPrice,
        quantity,
        setQuantity,
        totalPrice,
        setTotalPrice,
        textDates,
        setTextDates,
        dates,
        setDates,
        specialDay,
        setSpecialDay,
        requisitionData,
        setRequisitionResult,
        checkoutSuccess,
        setCheckoutSuccess,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}
//===================================================================================

