//React =======================================================================================================
import React, { useContext, useEffect, useState } from "react";
//Style =======================================================================================================
import {
  BlueBox,
  BlueBoxesContainer,
  CheckboxButton,
  CheckboxInputs,
  Container,
  DateShow,
  DateInput,
  RadioButton,
  RadioInputs,
  DatesShow,
  DeleteIcon,
  Title,
} from "./style";
//Contexts ====================================================================================================
import { PlanContext } from "../../../../Contexts/PlanContext";
import BlueButton from "../../Components/BlueButton";
import {
  validateInputDate,
  validateHolidayDate,
} from "../../../../Utils/validateDate";
//Components ==================================================================================================
import FormDivider from "../../Components/FormDivider";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useToast } from "@chakra-ui/react";
//=============================================================================================================

function LeftSection() {
  const [specificDate, setSpecificDate] = useState(); // ! usado somente aqui

  //* infos do One
  const {
    planName,
    planImage,
    planDescription,
    blueBoxes,
    holidaysDates,
    setHolidaysDates,
    arrangementsTypes,
    basePrice,
    arrangementTypePrice,
    arrangementQuantityPrice,
    setPrice,
  } = useContext(PlanContext);
  //* dados selecionados pelo usuário (localStorage)
  const {
    dates,
    setDates,
    specialDay,
    setSpecialDay,
    selectedArrangementType,
    setSelectedArrangementType,
    flowerArrangements,
    setFlowerArrangements,
  } = useContext(PlanContext);
  //* preços
  const { getArrangementTypePrice, getArrangementQuantityPrice } =
    useContext(PlanContext);

  const toast = useToast();

  // * método para lidar com seleção de feriados
  function handleHolidaysDatesSelection(e) {
    // Arrays para manipulação
    let holidaysDatesArray = [...holidaysDates];
    let datesArray = [...dates];
    let specialDayArray = [...specialDay];
    // Identifica o index da alteração
    const index = holidaysDatesArray.findIndex((date) => {
      return date.id === e.target.getAttribute("id");
    });

    // validação da data
    if (e.target.selected === false) {
      const message = validateHolidayDate(
        e.target.getAttribute("value"),
        dates
      );
      if (message.error) {
        toast({
          title: "Data inválida",
          description: message.error,
          status: "warning",
          duration: 4000,
          isClosable: true,
        });
        return;
      }

      // encontra o primeiro index de data 'yyyy-mm-dd' e insere a data
      const emptyIndex = datesArray.findIndex((date) => {
        return date === "yyyy-mm-dd";
      });
      datesArray[emptyIndex] = `${message.year}-${e.target.getAttribute(
        "value"
      )}`;

      holidaysDatesArray[index].selected = !e.target.selected;
      specialDayArray.push(e.target.getAttribute("id"));
    }

    if (e.target.selected === true) {
      // encontra o index da data e passa para 'yyyy-mm-dd' e inverte o status do feriado
      const dateIndex = datesArray.findIndex((date) => {
        return date.includes(e.target.getAttribute("value"));
      });

      specialDayArray = specialDayArray.filter(
        (day) => day !== e.target.getAttribute("id")
      );
      datesArray[dateIndex] = "yyyy-mm-dd";
      holidaysDatesArray[index].selected = !e.target.selected;
    }

    setSpecialDay(specialDayArray);
    setHolidaysDates(holidaysDatesArray);
    setDates(datesArray);
  }

  // * método para lidar com seleção de datas determinadas pelo usuário
  function handleDateInput() {
    let datesArray = [...dates];
    let holidaysDatesArray = [...holidaysDates];
    let specialDayArray = [...specialDay];

    const message = validateInputDate(specificDate, dates, holidaysDates);
    if (message.error) {
      toast({
        title: "Data inválida",
        description: message.error,
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    // checa se a data adicionada é um feriado, para marcar ela da parte de holidaysDates
    holidaysDatesArray.forEach((holiday) => {
      if (
        message.date.includes(holiday.date) &&
        holiday.id !== "dia-das-maes" &&
        holiday.id !== "dia-dos-pais"
      ) {
        holiday.selected = true;
        specialDayArray.push(holiday.id);
      }
    });

    // encontra o primeiro index de data null e insere a data
    const emptyIndex = datesArray.findIndex((date) => {
      return date === "yyyy-mm-dd";
    });
    datesArray[emptyIndex] = specificDate;
    setSpecialDay(specialDayArray);
    setDates(datesArray);
  }

  function handleRemoveDate(index) {
    let datesArray = [...dates];
    let holidaysDatesArray = [...holidaysDates];
    let specialDayArray = [...specialDay];

    // checa se a data removida é um feriado, para desmarcar ela da parte de holidaysDates
    holidaysDatesArray.forEach((holiday) => {
      if (datesArray[index].includes(holiday.date)) {
        holiday.selected = false;
        specialDayArray = specialDayArray.filter((day) => day !== holiday.id);
      }
    });
    datesArray[index] = "yyyy-mm-dd";
    setSpecialDay(specialDayArray);
    setDates(datesArray);
  }

  useEffect(() => {
    setPrice({
      plan: basePrice,
      arrangementType: arrangementTypePrice,
      flowerArrangements: arrangementQuantityPrice,
    });
  }, [basePrice, arrangementTypePrice, arrangementQuantityPrice]);

  useEffect(() => {
    setSelectedArrangementType({ id: "", name: "" });
  }, [setSelectedArrangementType]);

  useEffect(() => {
    // ? usar useReducer para gerenciar states complexos
    flowerArrangements === "3"
      ? setDates(["yyyy-mm-dd", "yyyy-mm-dd", "yyyy-mm-dd"])
      : flowerArrangements === "4"
      ? setDates(["yyyy-mm-dd", "yyyy-mm-dd", "yyyy-mm-dd", "yyyy-mm-dd"])
      : flowerArrangements === "5"
      ? setDates([
          "yyyy-mm-dd",
          "yyyy-mm-dd",
          "yyyy-mm-dd",
          "yyyy-mm-dd",
          "yyyy-mm-dd",
        ])
      : setDates([
          "yyyy-mm-dd",
          "yyyy-mm-dd",
          "yyyy-mm-dd",
          "yyyy-mm-dd",
          "yyyy-mm-dd",
          "yyyy-mm-dd",
        ]);

    // ! Remoção dos feriados quando se altera a quantidade de arranjos
    if (holidaysDates && holidaysDates.length > 0) {
      let holidaysDatesArray = [...holidaysDates];
      holidaysDatesArray.forEach((holiday) => {
        holiday.selected = false;
      });
      setHolidaysDates(holidaysDatesArray);
    }

    if (specialDay && specialDay.length > 0) {
      setSpecialDay([]);
    }
  }, [flowerArrangements]);

  return (
    <Container>
      <Title>{planName ? planName : <Skeleton width="35%" />}</Title>

      {planImage ? (
        <img
          src={planImage && planImage}
          style={{ borderRadius: 10 }}
          alt="blue-flower"
        />
      ) : (
        <Skeleton height="300px" />
      )}
      <p className="description">
        {planDescription ? planDescription : <Skeleton count={2} />}
      </p>
      <BlueBoxesContainer>
        {blueBoxes ? (
          blueBoxes.map((topic, index) => {
            return (
              <BlueBox key={index}>
                <p>{topic}</p>
              </BlueBox>
            );
          })
        ) : (
          <div style={{ width: "100%" }}>
            <Skeleton height={40} count={4} style={{ marginTop: "15px" }} />
          </div>
        )}
      </BlueBoxesContainer>

      <FormDivider title="1. Escolha uma opção de arranjo" />
      <RadioInputs direction="row">
        {arrangementsTypes ? (
          arrangementsTypes.map((arrangementType, index) => {
            return (
              <div key={index} className="inputGroup">
                <RadioButton
                  type="radio"
                  id={arrangementType.arrangementId}
                  value={arrangementType.arrangementId}
                  checked={
                    selectedArrangementType.id === arrangementType.arrangementId
                  }
                  name={arrangementType.arrangement}
                  onChange={(e) => {
                    setSelectedArrangementType({
                      id: e.target.value,
                      name: e.target.name,
                    });
                    getArrangementTypePrice(e.target.name);
                  }}
                />
                <label htmlFor={arrangementType.arrangementId}>
                  {arrangementType.arrangement}
                </label>
              </div>
            );
          })
        ) : (
          <div style={{ width: "100%" }}>
            <Skeleton height={80} />
          </div>
        )}
      </RadioInputs>

      <FormDivider title="2. Escolha a quantidade de datas de entrega de flores" />
      <RadioInputs direction="column">
        <div className="inputGroup">
          <RadioButton
            type="radio"
            id="3"
            value="3"
            checked={flowerArrangements === "3"}
            onChange={(e) => {
              setFlowerArrangements(e.target.value);
              getArrangementQuantityPrice(e.target.value);
            }}
          />
          <label htmlFor="3">3 arranjos ao ano</label>
        </div>

        <div className="inputGroup">
          <RadioButton
            type="radio"
            id="4"
            value="4"
            checked={flowerArrangements === "4"}
            onChange={(e) => {
              setFlowerArrangements(e.target.value);
              getArrangementQuantityPrice(e.target.value);
            }}
          />
          <label htmlFor="4">4 arranjos ao ano</label>
        </div>

        <div className="inputGroup">
          <RadioButton
            type="radio"
            id="5"
            value="5"
            checked={flowerArrangements === "5"}
            onChange={(e) => {
              setFlowerArrangements(e.target.value);
              getArrangementQuantityPrice(e.target.value);
            }}
          />
          <label htmlFor="5">5 arranjos ao ano</label>
        </div>

        <div className="inputGroup">
          <RadioButton
            type="radio"
            id="6"
            value="6"
            checked={flowerArrangements === "6"}
            onChange={(e) => {
              setFlowerArrangements(e.target.value);
              getArrangementQuantityPrice(e.target.value);
            }}
          />
          <label htmlFor="6">6 arranjos ao ano</label>
        </div>
      </RadioInputs>

      <FormDivider title="3. Escolha as datas de entrega das flores para o seu ente querido" />
      <CheckboxInputs>
        {holidaysDates &&
          holidaysDates.map((holiday) => {
            return (
              <CheckboxButton
                key={holiday.id}
                id={holiday.id}
                value={holiday.date}
                selected={holiday.selected}
                onClick={(e) => {
                  handleHolidaysDatesSelection(e);
                }}
              >
                {holiday.name}
              </CheckboxButton>
            );
          })}
      </CheckboxInputs>

      <FormDivider title="4. Ou escolha suas datas personalizadas" />
      <div style={{ display: "flex", alignItems: "center" }}>
        <DateInput
          type="date"
          value={specificDate}
          onChange={(e) => setSpecificDate(e.target.value)}
        />
        <BlueButton
          title="Inserir Data"
          margin="20px 0px 0px 10px"
          onClick={handleDateInput}
        >
          Inserir Data
        </BlueButton>
      </div>

      <FormDivider
        title={`Datas selecionadas (Você selecionou ${
          dates.filter((date) => date !== "yyyy-mm-dd").length
        } de ${dates.length} datas)`}
      />
      <DatesShow>
        {dates.map((date, index) => {
          return (
            <div key={index}>
              <DateShow
                id={index}
                type="date"
                value={dates[index]}
                disabled={date !== "yyyy-mm-dd"}
                readOnly={true}
              />
              <div className="icon" onClick={() => handleRemoveDate(index)}>
                <DeleteIcon />
              </div>
            </div>
          );
        })}
      </DatesShow>
    </Container>
  );
}

export default LeftSection;

